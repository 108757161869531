import moment from 'moment'
import EngineerAvailabilityModel from '@/models/contractor/EngineerAvailabilityModel'

export default class EngineerModel {
  public id: string | null
  public firstName: string
  public lastName: string
  public email: string
  public contactNumber: string
  public address: string
  public outsideFromDate: moment.Moment | null
  public outsideToDate: moment.Moment | null
  public isActive: boolean
  public engineerAvailability: EngineerAvailabilityModel[]
  public contractorId: string
  public hasPortalAccess: boolean
  public name: string
  public profileImageUrl: string | undefined
  public profileImageThumbnailUrl: string | undefined

  public constructor() {
    if (!this.engineerAvailability) {
      this.engineerAvailability = []
    }
    this.contactNumber = ''
    this.hasPortalAccess = false
    this.name = this.firstName + ' ' + this.lastName
  }
}
