import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

export function startDatadog() {
  // `site` refers to the Datadog site parameter of your organization
  // see https://docs.datadoghq.com/getting_started/site/
  const site = 'datadoghq.eu'
  const clientToken = process.env.VUE_APP_DatadogClientToken || ''
  const service = 'simplifi.callcentre.app'
  const env = process.env.VUE_APP_EnvironmentName || 'development'

  // Specify a version number to identify the deployed version of your application in Datadog
  const version = process.env.VUE_APP_DatadogCiBuildId

  datadogRum.init({
    applicationId: process.env.VUE_APP_DatadogApplicationId || '',
    clientToken,
    site,
    service,
    env,
    version,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 10,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [process.env.VUE_APP_CallCentreApiBaseUrl || ''],
    enableExperimentalFeatures: ['feature_flags'],
  })

  datadogLogs.init({
    clientToken,
    site,
    service,
    env,
    version,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: 'all',
  })
}

export function getSessionId() {
  return datadogLogs.getInternalContext()?.session_id
}

export const logger = datadogLogs.logger

export function onTokenChanged(token: string) {
  const jwt = parseJwt(token)
  const context = {
    user: {
      id: jwt.extension_SimplifiUserID || 'Unknown',
      name: jwt.name,
      full_name: jwt.name,
      email: jwt.emails[0] || '',
      roles: (jwt.extension_Role || '').split(',') || [],
    },
    git: {
      repository_url: process.env.VUE_APP_RepositoryUrl,
      commit: {
        sha: process.env.VUE_APP_GitCommitSha,
      },
    },
    simplifi: {
      user: {
        account_id: jwt.sub,
        type: jwt.extension_SimplifiUserType || 'Unknown',
        id: jwt.extension_SimplifiUserID || 'Unknown',
        contractor_id: jwt.extension_ContractorId,
        client_id: jwt.extension_ClientId,
        policy_schedule_ids: jwt.extension_AllowedPolicyScheduleIds
          ? jwt.extension_AllowedPolicyScheduleIds.split(',')
          : null,
        permissions: jwt.extension_Permissions ? jwt.extension_Permissions.split(',') : [],
        job_types: jwt.extension_JobTypes ? jwt.extension_JobTypes.split(',') : null,
      },
    },
  }
  datadogRum.setGlobalContext(context)
  datadogLogs.setGlobalContext(context)
}

function parseJwt(token: string): any {
  // JWT is in the format `header.payload.signature` base64Url encoded.
  // JWT uses base64Url encoding, which is different from base64 encoding.
  // atob can also have problems with unicode characters, so this method handles that.
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
      .join('')
  )

  return JSON.parse(jsonPayload)
}
