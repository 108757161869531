<template>
  <div v-bar="{ useScrollbarPseudo: true }">
    <v-dialog v-model="showDialog" max-width="900" persistent content-class="v-dialog--scrollable flex-col">
      <EditEngineer
        v-if="showDialog"
        :existing-engineer="engineer"
        :is-update="engineerUpdate"
        :available-trades="engineerTradeAvailability"
        @close="showDialog = false"
        @save="onEngineerSaved"
      />
    </v-dialog>

    <v-card class="pa-3">
      <v-card-title primary-title class="pa-0">
        <h3 class="mb-0">Engineer List</h3>
        <v-spacer></v-spacer>

        <v-text-field v-model="search" append-icon="search" label="Search" class="mt-0 pt-0" single-line hide-details />

        <v-tooltip v-if="!isIndependent && !isUserRoleContractor" top>
          <span>Add Engineer</span>

          <template #activator="{ on }">
            <v-btn icon small class="mx-0 ml-3 rounded-none" color="primary" v-on="on" @click="onCreateEngineer">
              <v-icon small>add</v-icon>
            </v-btn>
          </template>
        </v-tooltip>
      </v-card-title>

      <div>
        <v-data-table
          :headers="headers"
          :items="getEngineers"
          :loading="isLoading"
          :search="search"
          :pagination.sync="pagination"
          class="gridView"
        >
          <template #items="props">
            <td class="profile-picture">
              <img :src="props.item.profileImageThumbnailUrl || '/img/unknownuser.png'" />
            </td>
            <td>{{ props.item.firstName }}</td>
            <td>{{ props.item.lastName }}</td>
            <td>{{ props.item.email }}</td>
            <td>{{ props.item.contactNumber }}</td>
            <td>{{ props.item.address }}</td>
            <td class="justify-center layout px-0">
              <v-tooltip top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0" v-on="on" @click="onEditClick(props.item)">
                    <v-icon color="primary">edit</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
              <v-tooltip v-if="!isIndependent" top>
                <template #activator="{ on }">
                  <v-btn icon class="mx-0 mt-2 ml-2" v-on="on" @click="onDeleteClick(props.item)">
                    <v-icon color="primary">delete</v-icon>
                  </v-btn>
                </template>
                <span>Delete</span>
              </v-tooltip>
              <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
                {{ snackbarText }}
                <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
              </v-snackbar>
            </td>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import EditEngineer from '@/components/ContractorManagement/Engineer.vue'
import EngineerModel from '@/models/contractor/EngineerModel'
import EngineerController from '@/api/engineerController'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import Shared from '@/common/shared'
import store from '@/store'
import eventBus from '@/common/bus'

@Component({
  components: { EditEngineer },
})
export default class EngineerManagement extends Vue {
  @Prop() public contractorId: string
  @Prop() public isIndependent: boolean
  @Prop() private engineerAvailability: ContractorTradeAvailabilityModel[]
  @Prop() private engineers: EngineerModel[]

  public showDialog = false
  public headers: any = []
  public engineer: EngineerModel | null = new EngineerModel()
  private engineerList: EngineerModel[] = []
  public isLoading = false
  public search = ''
  public pagination: any = {}
  public engineerUpdate = false
  public snackbar = false
  public snackbarTimeout = 3000
  public snackbarText = ''
  public engineerTradeAvailability: ContractorTradeAvailabilityModel[] = []

  private created() {
    this.headers = [
      { text: ' ', value: 'profileImageThumbnailUrl', class: 'profile-picture-header', sortable: false },
      { text: 'First Name', value: 'firstName' },
      { text: 'Last Name', value: 'lastName' },
      { text: 'Email', value: 'email' },
      { text: 'Contact Number', value: 'contactNumber' },
      { text: 'Address', value: 'address' },
      { text: '', sortable: false },
    ]
    this.pagination.rowsPerPage = 10
    this.engineerList = this.engineers
  }

  @Watch('engineers')
  private onEngineerListChange(newValue: EngineerModel[]) {
    this.getEngineers = newValue
  }

  @Watch('engineerAvailability', { immediate: true })
  private onEngineerAvailabilityChange(newValue: ContractorTradeAvailabilityModel[]) {
    this.engineerTradeAvailability = [...newValue].sort((a, b) => a.description.localeCompare(b.description))
  }

  public get getEngineers(): EngineerModel[] {
    return this.engineerList
  }
  public set getEngineers(newValue: EngineerModel[]) {
    this.engineerList = newValue
  }

  // Check if userRole is contractor
  public get isUserRoleContractor(): boolean {
    return store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private getEngineerList() {
    this.isLoading = true
    EngineerController.GetContractorEngineers(this.contractorId).then((res: EngineerModel[]) => {
      this.getEngineers = res
      eventBus.$emit('engineerListUpdated', res)
      this.isLoading = false
    })
  }

  private getBackgroundColourStyle() {
    return 'background-color:' + this.$vuetify.theme.primary
  }

  public onCreateEngineer() {
    this.engineerUpdate = false
    this.engineer = new EngineerModel()
    if (this.engineer) {
      this.engineer.contractorId = this.contractorId
    }
    this.showDialog = true
  }

  public onEditClick(engineerItem: EngineerModel) {
    this.engineer = new EngineerModel()
    this.engineerUpdate = true

    this.isLoading = true
    if (engineerItem.id) {
      EngineerController.get(engineerItem.id)
        .then((res) => {
          if (res !== null) {
            this.engineer = res
            this.showDialog = true
          }
          this.isLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading engineer detail, please try again', true)
          this.isLoading = false
          this.showDialog = false
        })
    }
  }

  public onDeleteClick(engineerItem: EngineerModel) {
    Shared.confirmationPopup.open(
      'Do you really want to deactivate engineer?',
      '',
      '',
      '',
      'Deactive',
      this,
      'DeactivateEngineer',
      engineerItem
    )
  }

  private DeactivateEngineer(engineerItem: EngineerModel) {
    this.isLoading = true
    if (engineerItem.id) {
      EngineerController.DeactivateEngineer(engineerItem.id)
        .then((res: boolean) => {
          if (res) {
            this.getEngineers.splice(this.getEngineers.indexOf(engineerItem), 1)
            this.getEngineers = [...this.getEngineers]
            this.snackbarText = 'Engineer is deactivated.'
            this.snackbar = true
            this.isLoading = false
          }
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error submitting deactivate engineer rrquest, please try again', true)
          this.isLoading = false
        })
    }
  }

  public onEngineerSaved(isDetailUpdated: boolean, isFileUploaded: boolean) {
    this.engineerUpdate = false
    this.showDialog = false
    // if detail is updated, then call API to get updated list
    if (isDetailUpdated) {
      // wait till file is uploaded to azure storage
      setTimeout(
        () => {
          this.getEngineerList()
        },
        isFileUploaded ? 10000 : 0
      )
    }
  }
}
</script>

<style scoped>
.gridView >>> .profile-picture-header {
  max-width: 50px;
  width: 10px;
}
.profile-picture {
  margin-top: 5px;
  display: inline-block;
  margin-bottom: 5px;
}
.profile-picture img {
  max-height: 50px;
  max-width: 50px;
  border: 3px solid #d2d2d2;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}
</style>
