<template>
  <div v-if="deployment">
    <v-flex class="mb-2">
      <AutoDeploymentDetail :deployment="deployment" />
    </v-flex>
    <v-flex v-if="deployment.contractors.length">
      <v-expansion-panel :value="accordionIndexToOpen">
        <v-expansion-panel-content
          v-for="(contractor, index) in deployment.contractors"
          :key="`deployment-${index}`"
          :hide-actions="
            contractor.appointmentRequest && contractor.appointmentRequest.status === deploymentStatusEnum.NotOffered
          "
        >
          <template #header>
            <v-flex>
              <v-layout justify-space-between align-center>
                <v-layout column>
                  <h5 class="text-uppercase">{{ contractor.contractorName }}</h5>
                  <h5>{{ index + 1 }}. {{ contractor.strategy }}</h5>
                </v-layout>
                <v-chip
                  small
                  :color="
                    contractor.appointmentRequest
                      ? deploymentStatusMap[contractor.appointmentRequest.status].color
                      : 'grey'
                  "
                  text-color="white"
                >
                  {{
                    contractor.appointmentRequest
                      ? deploymentStatusMap[contractor.appointmentRequest.status].text
                      : 'Not Offered'
                  }}</v-chip
                >
              </v-layout>
            </v-flex>
          </template>
          <AutoDeploymentContractorDetail
            v-if="
              contractor.appointmentRequest && contractor.appointmentRequest.status !== deploymentStatusEnum.NotOffered
            "
            :contractor-appointed-id="contractor.contractorId"
            :deployment-id="deploymentId"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-flex>
    <v-card v-else class="pa-5 text-center"
      ><h3>No contractors found for this area, advising manual deployment</h3></v-card
    >
  </div>
  <div v-else>Deployment Currently Not Running</div>
</template>

<script lang="ts">
import { AutoDeployment, AppointmentRequestStatus, DeploymentStatusMap } from '@/models/autoDeployment/auto-deployment'
import { Component, Prop } from 'vue-property-decorator'
import { useDeploymentStore } from '@/pinia/deployment'
import AutoDeploymentDetail from './AutoDeploymentDetail.vue'
import AutoDeploymentContractorDetail from './AutoDeploymentContractorDetail.vue'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'
import TimeLineItemBase from '@/components/TimeLineItemBase'

@Component({
  components: {
    AutoDeploymentDetail,
    AutoDeploymentContractorDetail,
  },
})
export default class AutoDeploymentCardPreview extends TimeLineItemBase {
  public deploymentStatusEnum = AppointmentRequestStatus
  public deploymentStatusMap = DeploymentStatusMap

  public async created() {
    if (!this.deploymentId) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('Failed to retrieve deploymentId, please contact support.', 0)
      )
      return
    }
    const deploymentStore = useDeploymentStore()
    await deploymentStore.retrieveAutoDeploymentDetail(this.jobId, this.deploymentId)
  }

  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public get deploymentId(): string | null {
    return this.card ? this.card.data['deploymentId'] : null
  }

  public get deployment(): AutoDeployment | null {
    return useDeploymentStore().autoDeployment
  }

  public get accordionIndexToOpen(): number {
    if (this.deployment === null || !this.deployment.contractors.length) {
      return 0
    }
    const acceptedJobIndex = this.deployment.contractors.findIndex(
      (x) => x.appointmentRequest?.status === AppointmentRequestStatus.Accepted
    )
    // Always displayed the accepted job. If no job is accepted, displayed the most recently offered one
    return ~acceptedJobIndex ? acceptedJobIndex : this.deployment.contractors.length - 1
  }
}
</script>
