<template>
  <div>{{ cardText }}</div>
</template>

<script lang="ts">
import TimeLineItemBase from '@/components/TimeLineItemBase'
import TimelineCardModel from '@/models/general/timeline'
import { useTimelineStore } from '@/pinia/timeline'
import { Component } from 'vue-property-decorator'

@Component
export default class AutoDeploymentCard extends TimeLineItemBase {
  public get card(): TimelineCardModel | null {
    const store = useTimelineStore()
    const card = store.cards.find((x) => x.id === this.itemId)
    return card || null
  }

  public get cardText(): string {
    if (!this.card) {
      return 'The deployment process cannot be started'
    }
    const contractorCount: number = this.card.data['numberOfContractors']
    return contractorCount > 0
      ? `The deployment process has started successfully with ${contractorCount} contractors involved.`
      : 'The deployment process cannot be started as there are no contractors available.'
  }
}
</script>
