import JobController from '@/api/jobController'
import eventBus from '@/common/bus'
import TimelineCardModel from '@/models/general/timeline'

import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTimelineStore = defineStore('Timeline', () => {
  const cards = ref<TimelineCardModel[]>([])

  async function retrieveTimelineCards(jobId: string): Promise<void> {
    cards.value = await JobController.GetTimelineCards(jobId)
    eventBus.$emit(
      'timelineCardsAdded',
      cards.value.map(({ type, id }) => {
        return { type, id }
      })
    )
    cards.value.push()
  }

  function updateTimelineCard(card: TimelineCardModel) {
    const index = cards.value.findIndex((x) => x.id === card.id)
    if (~index) {
      cards.value[index] = card
    } else {
      cards.value.push(card)
    }
    eventBus.$emit('timelineCardsAdded', [{ type: card.type, id: card.id }])
  }

  return {
    cards,
    retrieveTimelineCards,
    updateTimelineCard,
  }
})
