<template>
  <div :class="contractorshow ? 'mid-section-content contractor-section py-3' : 'contractor-section py-3'">
    <v-progress-circular
      v-show="!getContractor"
      :width="2"
      :size="50"
      indeterminate
      color="primary"
      :style="getLoaderStyle(70)"
    ></v-progress-circular>
    <!--TODO: if the getContractor is null, show a nice message-->
    <div
      v-if="getContractor"
      :class="
        (contractordropdown && !isUserRoleContractor) ||
        (isUserRoleContractor && (hasAnySubcontractor || showSelectVisitTypeDropdown))
          ? 'showcontractor'
          : ''
      "
    >
      <div
        v-if="contractordropdown"
        wrap
        class="contractor-mgm-search"
        :class="
          isUserRoleContractor && showSelectVisitTypeDropdown && !hasAnySubcontractor ? 'contractor-search-cn' : ''
        "
      >
        <v-autocomplete
          v-if="showSelectVisitTypeDropdown"
          v-model="selectedVisitType"
          :items="getVisitTypes"
          item-text="alterText"
          item-value="description"
          label="Visit Type"
          class="visit-type mr-3"
          :class="isUserRoleContractor && hasAnySubcontractor ? 'visit-type-space' : ''"
          @change="filterJobsBySelectedVisitType($event)"
        ></v-autocomplete>
        <template v-if="!isUserRoleContractor">
          <v-select
            v-model.trim="selectedPreferredContractor"
            :items="getPreferredContractorOptions"
            label="Contractor Status"
            item-text="description"
            item-value="id"
            class="contractor_status"
            :disabled="isVisitSummaryDataLoading"
          ></v-select>
          <v-autocomplete
            v-model="selectedContractorId"
            v-validate="'required'"
            label="Search contractor"
            required
            class="required ml-3 contractor_list"
            :loading="getContractorDetailLoading || getContractorJobsLoading || getContractorEngineersLoading"
            :items="contractorList"
            item-text="companyName"
            item-value="id"
            data-vv-scope="frmContractor"
            data-vv-name="Search contractor"
            :error-messages="errors.collect('Search contractor')"
            :disabled="isVisitSummaryDataLoading"
          ></v-autocomplete>
          <v-btn
            class="ma-0 mt-2 add-contractor-btn"
            color="primary"
            :disabled="isVisitSummaryDataLoading"
            @click="addContractorClick()"
          >
            <v-icon small>person_add</v-icon>
            &nbsp; Contractor
          </v-btn>
        </template>
      </div>
      <div v-if="isUserRoleContractor && hasAnySubcontractor" wrap class="contractor-mgm-search contractor-search-cn">
        <v-autocomplete
          v-model="selectedContractorId"
          v-validate="'required'"
          label="Search contractor"
          required
          class="required ml-3 contractor_list"
          :loading="getContractorDetailLoading || getContractorJobsLoading || getContractorEngineersLoading"
          :items="subcontractors"
          item-text="companyName"
          item-value="id"
          data-vv-scope="frmContractor"
          data-vv-name="Search contractor"
          :error-messages="errors.collect('Search contractor')"
        ></v-autocomplete>
      </div>
      <v-tabs v-model="activeTab" icons-and-text :hide-slider="true">
        <v-tabs-slider color="yellow"></v-tabs-slider>
        <div v-bar="{ useScrollbarPseudo: true }" class="tab-wrap">
          <v-layout>
            <div
              v-if="
                contractor?.preferredContractor &&
                contractor.preferredContractor !== 'ApplicationPending' &&
                contractor.preferredContractor !== 'ApplicationInProgress' &&
                contractor.preferredContractor !== 'Archived'
              "
              class="badge-img badges mt-1"
            >
              <img :src="'/img/' + contractor.preferredContractor + '-Badge.png'" />
            </div>
            <v-tab href="#tab-1" @click.native="removecontspec(true)">
              Jobs
              <v-icon>date_range</v-icon>
            </v-tab>
            <v-tab href="#tab-2" @click.native="removecontspec()">
              Contractor Profile
              <svg
                class="mb-1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                viewBox="0 0 297 297"
                enable-background="new 0 0 297 297"
                width="25px"
                height="25px"
              >
                <g>
                  <path
                    d="M148.51,117.216c32.317,0,58.608-26.291,58.608-58.608S180.827,0,148.51,0c-32.317,0-58.608,26.291-58.608,58.608   S116.193,117.216,148.51,117.216z"
                    fill="#757575"
                  ></path>
                  <path
                    d="m227.154,145.618c-0.025-0.008-0.073-0.026-0.098-0.032-7.631-1.864-30.999-5.133-30.999-5.133-2.638-0.812-5.457,0.585-6.406,3.188l-35.174,96.509c-2.029,5.567-9.903,5.567-11.932,0l-35.174-96.509c-0.766-2.102-2.75-3.42-4.876-3.42-0.504,0-24.531,3.369-32.53,5.358-21.858,5.435-35.645,26.929-35.645,49.329v80.302c0,12.034 9.756,21.79 21.79,21.79h184.782c12.034,0 21.79-9.756 21.79-21.79v-80.569c-0.001-22.303-14.328-42.096-35.528-49.023z"
                    fill="#757575"
                  ></path>
                  <path
                    d="m161.775,138.613c-1.404-1.53-3.456-2.299-5.532-2.299h-15.485c-2.076,0-4.129,0.77-5.532,2.299-2.173,2.368-2.489,5.789-0.946,8.462l8.278,12.479-3.875,32.69 7.631,20.3c0.744,2.042 3.631,2.042 4.375,0l7.631-20.3-3.875-32.69 8.278-12.479c1.541-2.673 1.225-6.094-0.948-8.462z"
                    fill="#757575"
                  ></path>
                </g>
              </svg>
            </v-tab>
            <v-tab href="#tab-9" @click.native="removecontspec()">
              Bank Details
              <v-icon>credit_card</v-icon>
            </v-tab>
            <v-tab v-if="!isUserRoleContractor" href="#tab-3" @click.native="addcontspec">
              Availability
              <v-icon>access_time</v-icon>
            </v-tab>
            <v-tab href="#tab-4" @click.native="removecontspec()">
              Engineers
              <svg
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                width="25px"
                height="25px"
                viewBox="0 0 535.806 535.807"
                style="enable-background: new 0 0 535.806 535.807"
                xml:space="preserve"
              >
                <g>
                  <path
                    d="M440.956,373.932c-11.934-13.158-26.315-19.584-44.676-19.584h-38.686l-25.398-24.479   c-18.666,15.3-41.31,24.174-65.791,24.174c-22.95,0-44.676-7.956-62.424-21.726l-22.645,21.726h-40.262   c-20.502,0-36.414,7.038-48.96,21.421c-36.414,42.227-30.294,132.498-27.54,160.344h407.592   C474.31,507.654,477.982,415.242,440.956,373.932z"
                    fill="#757575"
                  ></path>
                  <path
                    d="M160.343,182.376c-7.344,6.12-12.24,15.912-12.24,27.234c0,16.83,11.016,30.6,25.092,33.048   c3.06,25.398,13.464,47.736,29.07,64.26c3.365,3.366,6.731,6.732,10.403,9.486c4.591,3.672,9.486,6.732,14.688,9.486   c11.628,6.119,24.479,9.485,38.25,9.485c13.77,0,26.623-3.366,38.25-9.485c5.202-2.754,10.098-5.814,14.688-9.486   c3.673-2.754,7.038-6.12,10.404-9.486c15.3-16.523,26.01-38.556,28.764-63.954c0.307,0,0.612,0,0.918,0   c16.219,0,29.07-14.994,29.07-33.354c0-11.322-4.896-21.114-12.24-27.234H160.343L160.343,182.376z"
                    fill="#757575"
                  ></path>
                  <path
                    d="M377.409,118.116c-9.486-31.518-34.578-63.648-66.402-80.172v71.91v9.792c0,0.612,0,0.918,0,1.224   c-0.306,3.366-0.918,6.426-2.447,9.486c-3.979,7.65-11.935,13.158-21.114,13.158h-4.896h-33.66c-8.568,0-16.219-4.59-20.196-11.322   c-1.836-2.754-2.754-5.813-3.366-9.18c-0.306-1.224-0.306-2.142-0.306-3.366v-8.568v-73.44   c-31.824,16.83-56.916,48.96-66.402,80.478l-2.142,6.732h-17.442v38.25h19.278h26.928h11.322h147.493h11.016h41.7v-1.836v-36.414   h-17.22L377.409,118.116z"
                    fill="#757575"
                  ></path>
                  <path
                    d="M248.777,134.028h38.25c5.508,0,10.098-3.06,12.546-7.65c1.224-2.142,1.836-4.284,1.836-6.732v-2.754V105.57V33.354V22.95   v-3.978c0-2.448-0.612-4.59-1.224-6.732C297.432,5.202,290.394,0,282.438,0h-33.661c-7.344,0-13.464,5.508-14.076,12.546   c0,0.612-0.306,1.224-0.306,1.836v8.568v10.404v73.44v11.628v1.224c0,3.06,0.918,5.814,2.448,8.262   C239.598,131.58,243.881,134.028,248.777,134.028z"
                    fill="#757575"
                  ></path>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </v-tab>
            <v-tab v-if="!isUserRoleContractor" href="#tab-5" @click.native="removecontspec()">
              Coverage
              <v-icon>place</v-icon>
            </v-tab>
            <v-tab v-if="!isUserRoleContractor" href="#tab-6" @click.native="removecontspec()">
              Rate
              <img src="/img/pound-symbol-grey.svg" alt="" class="menu-icon menu-images" />
              <img src="/img/pound-symbol-pink.svg" alt="" class="active-menu-icon menu-images" />
            </v-tab>
            <v-tab v-if="!isUserRoleContractor" href="#tab-7" @click.native="disablecontractor">
              Rate Template
              <img src="/img/pound-symbol-grey.svg" alt="" class="menu-icon menu-images" />
              <img src="/img/pound-symbol-pink.svg" alt="" class="active-menu-icon menu-images" />
            </v-tab>
            <v-tab href="#tab-8" @click.native="removecontspec()">
              Financial Summary
              <img src="/img/finance-grey.svg" alt="" class="menu-icon menu-images" />
              <img src="/img/finance-pink.svg" alt="" class="active-menu-icon menu-images" />
            </v-tab>
            <v-tab href="#tab-10" @click.native="removecontspec()">
              Trade Association Memberships
              <v-icon>people</v-icon>
            </v-tab>
            <v-tab href="#tab-11" @click.native="removecontspec()">
              Health, Safety, Wellbeing and Environment
              <svg
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                version="1.1"
                x="0px"
                y="0px"
                viewBox="0 0 455 455"
                style="enable-background: new 0 0 455 455"
                xml:space="preserve"
                fill="#949494"
                width="25px"
                height="25px"
              >
                <g>
                  <polygon
                    points="370.5,158.498 310.5,158.498 310.5,227.498 241.5,227.498 241.5,287.498 310.5,287.498 310.5,356.498    370.5,356.498 370.5,287.498 439.5,287.498 439.5,227.498 370.5,227.498  "
                  />
                  <path
                    d="M211.5,197.498h69v-69h120v69h34.799c12.468-20,19.701-42.674,19.701-67.53C455,60.686,398.847,4.51,329.579,4.51   c-42.087,0-79.329,20.731-102.079,52.544C204.75,25.24,167.508,4.501,125.421,4.501C56.153,4.501,0,60.664,0,129.947   c0,30.118,10.612,57.752,28.299,79.376L227.5,450.499l53-64.169v-68.832h-69V197.498z"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </v-tab>
            <v-tab href="#tab-12" @click.native="removecontspec()">
              Signature
              <v-icon>edit</v-icon>
            </v-tab>
            <!-- TODO: put these back in when the areas are finished
        <v-tab
          href="#tab-8"
          @click.native="removecontspec"
          v-if="!isUserRoleContractor"
        >Add non-cet jobs
          <v-icon>arrow_upward</v-icon>
        </v-tab>
        <v-tab href="#tab-9" @click.native="removecontspec" v-if="!isUserRoleContractor">Reports
          <v-icon>bar_chart</v-icon>
        </v-tab>
        -->
          </v-layout>
        </div>
        <v-tab-item value="tab-1">
          <v-card flat class="grey lighten-3 elevation-1 card-box-shadow-none">
            <ContractorJobs
              v-if="getContractor"
              :id="getContractorId"
              :contractor-jobs="getJobContractors"
              :engineers="getEngineers"
              :selected-job-to-open="selectedJobToOpen"
              :visit-type="selectedVisitType"
            ></ContractorJobs>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="activeTab === 'tab-2'" value="tab-2">
          <v-card flat class="grey lighten-3">
            <ContractorProfile
              :contractor-detail="Object.assign({}, getContractor)"
              :total-engineer="getTotalEngineersLength"
              :engineer.sync="getFirstEngineerDetails"
              :contractor-list="contractorList"
              class="contractor-profile-content"
              @isIndependentContractor="isIndependentContractor"
            ></ContractorProfile>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="activeTab === 'tab-9'" value="tab-9">
          <v-card flat>
            <div>
              <v-card flat class="elevation-1 card-box-shadow-none">
                <v-card-title><h3>Bank Details</h3></v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <ContractorBankDetails
                    ref="contractorBankDetails"
                    :contractor-bank-detail="getContractorBankDetail"
                    :contractor-id="getContractorId"
                    @updateBankDetail="onContractorBankDetailUpdate"
                  ></ContractorBankDetails>
                </v-card-text>
              </v-card>
            </div>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="!isUserRoleContractor && activeTab === 'tab-3'" value="tab-3">
          <v-card class="pa-3 contractor-specialization-content pr-0">
            <div v-bar="{ useScrollbarPseudo: true }" class="fill-height pr-3">
              <div>
                <ContractorSpecialization
                  v-if="getContractor"
                  :contractor-id="getContractor.id"
                  :contractor="getContractor"
                  @updateContractorAvailability="onContractorAvailabilityUpdate"
                ></ContractorSpecialization>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="activeTab === 'tab-4'" value="tab-4">
          <v-card flat class="grey lighten-3">
            <div class="elevation-1">
              <EngineerManagement
                v-if="getContractor"
                :contractor-id="getContractorId"
                :is-independent="isIndependent"
                :engineer-availability="
                  getContractor.contractorAvailability ? getContractor.contractorAvailability : []
                "
                :engineers.sync="getEngineers"
              ></EngineerManagement>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isUserRoleContractor && activeTab === 'tab-5'" value="tab-5">
          <v-card flat class="grey lighten-3">
            <div>
              <ContractorArea
                v-if="getContractor"
                :contractor-id="getContractor.id"
                :coverage="getContractorCoverage"
                :contractor-availability="getContractorAvailability"
                @coverageChange="onCoverageChange"
              ></ContractorArea>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isUserRoleContractor" value="tab-6" class="px-3">
          <v-card class="grey lighten-3" flat>
            <ContractorRate
              :all-contractor-rate-package-list.sync="allContractorRatePackageList"
              :contractor-id="getContractor.id"
              :contractor-name="getContractor.companyName"
              :rate-package-id.sync="getContractor.ratePackageId"
            ></ContractorRate>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="!isUserRoleContractor" value="tab-7" class="px-3">
          <v-card class="grey lighten-3" flat>
            <ContractorRate :all-contractor-rate-package-list.sync="allContractorRatePackageList"></ContractorRate>
          </v-card>
        </v-tab-item>
        <v-tab-item v-if="activeTab === 'tab-8'" value="tab-8">
          <v-card flat class="grey lighten-3">
            <div>
              <ContractorFinancialSummary
                v-if="getContractor"
                :contractor-id="selectedContractorId"
                :is-visit-summary-data-loading.sync="isVisitSummaryDataLoading"
                :engineers="engineers"
              ></ContractorFinancialSummary>
            </div>
          </v-card>
        </v-tab-item>
        <!-- TODO: commented out until the work for these areas is done
        <v-tab-item id="tab-8" v-if="!isUserRoleContractor">
          <v-card flat class="grey lighten-3 elevation-1">
            <v-card-text>Add Non CET Jobs</v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item id="tab-9" v-if="!isUserRoleContractor">
          <v-card flat class="grey lighten-3 elevation-1">
            <v-card-text>Report</v-card-text>
          </v-card>
        </v-tab-item> -->

        <v-tab-item v-if="activeTab === 'tab-10'" value="tab-10">
          <v-card>
            <v-card-title>
              <h3>Trade Association Memberships</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <ContractorTradeAssociationMemberships
                v-if="getContractor"
                :contractor-id="getContractor.id"
                :contractor="getContractor"
                :document-detail-list="documentDetailList"
                @onSaveTradeMembershipDetails="onSaveTradeMembershipDetails"
              ></ContractorTradeAssociationMemberships>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="activeTab === 'tab-11'" value="tab-11">
          <v-card>
            <v-card-title>
              <h3>Health, Safety, Wellbeing and Environment</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <ContractorHSAndWellbeingEnvironment
                v-if="getContractor"
                :health-safety-wellbeing-environment="getContractor.healthSafetyWellbeingEnvironment"
                :contractor-id="getContractor.id"
                :document-detail-list="documentDetailList"
                @onSaveHealthSafetyWellbeingEnvironment="onSaveHealthSafetyWellbeingEnvironment"
              ></ContractorHSAndWellbeingEnvironment>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="activeTab === 'tab-12'" value="tab-12">
          <v-card>
            <v-card-title>
              <h3>Declaration</h3>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <ContractorSignature
                v-if="getContractor"
                :contractor-id="getContractor.id"
                :contractor-signature="getContractor.signature"
                @onSaveContractorSignatureDetails="onSaveContractorSignatureDetails"
              ></ContractorSignature>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </div>
    <v-dialog
      v-if="showContractorProfileDialog"
      v-model="showContractorProfileDialog"
      max-width="650"
      persistent
      content-class="v-dialog--scrollable"
    >
      <div v-bar="{ useScrollbarPseudo: true }">
        <div>
          <ContractorProfile
            :contractor-detail="contractorProfileData"
            :show-contractor-profile-in-dialog="true"
            @saveContractor="saveContractor"
            @onCancelChanges="showContractorProfileDialog = false"
          ></ContractorProfile>
        </div>
      </div>
    </v-dialog>
    <ContractorJobOfferCard
      v-if="jobId && contractorAppointedId && isLegacyAutoDeploymentEnabled"
      :job-id="jobId"
      :contractor-appointed-id="contractorAppointedId"
      :get-engineers="getEngineers"
      @showSnackBarMessage="showSnackBarMessage"
      @closeJobOfferDialog="closeJobOfferDialog"
    ></ContractorJobOfferCard>
    <ContractorJobOfferModal
      v-else-if="jobId && contractorAppointedId"
      :job-id="jobId"
      :appointment-id="contractorAppointedId"
      @closeJobOfferDialog="closeJobOfferDialog"
    />

    <v-snackbar v-model="snackbar" :timeout="snackbarTimeout" left bottom>
      {{ snackbarText }}
      <v-btn dark flat color="secondary" @click.native="snackbar = false">close</v-btn>
    </v-snackbar>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator'
import ContractorJobs from '@/components/ContractorJobs.vue'
import ContractorSpecialization from '@/components/ContractorSpecialization.vue'
import ContractorArea from '@/components/ContractorArea.vue'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorController from '@/api/contractorController'
import JobController from '@/api/jobController'
import TradeModel from '@/models/policyHolder/TradeModel'
import ContractorRate from '@/components/ContractorRate.vue'
import EngineerManagement from '@/components/EngineerManagement.vue'
import Store from '@/store'
import RateController from '@/api/rateController'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import EngineerController from '@/api/engineerController'
import EngineerModel from '@/models/contractor/EngineerModel'
import ContractorProfile from '@/views/ContractorProfile.vue'
import ContractorManagementHub from '@/components/ContractorManagementHub.vue'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ContractorFinancialSummary from '@/components/ContractorFinancialSummary.vue'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import Shared from '@/common/shared'
import eventBus from '@/common/bus'
import BankAccountDetail from '@/models/contractor/BankAccountDetailModel'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import ContractorTradeAssociationMemberships from '@/components/ContractorTradeAssociationMemberships.vue'
import HealthSafetyWellbeingEnvironment from '@/models/contractor/HealthSafetyWellbeingEnvironment'
import ContractorHSAndWellbeingEnvironment from '@/components/ContractorHSAndWellbeingEnvironment.vue'
import SignatureModel from '@/models/contractor/SignatureModel'
import ContractorSignature from '@/components/ContractorSignature.vue'
import ContractorBankDetails from '@/components/ContractorBankDetails.vue'
import { IPreferredContractor, PreferredContractors, ContractorVisitTypeEnum } from '@/common/enums'
import ContractorJobOfferCard from '@/components/ContractorJobOfferCard.vue'
import ContractorJobOfferModal from '@/components/ContractorManagement/ContractorJobOfferModal.vue'
import { useDeploymentStore } from '@/pinia/deployment'

interface IDropdownType {
  id: number
  description: string
  alterText?: string
}

// tslint:disable:no-console

@Component({
  components: {
    ContractorJobs,
    ContractorSpecialization,
    ContractorArea,
    ContractorRate,
    EngineerManagement,
    ContractorProfile,
    ContractorFinancialSummary,
    ContractorTradeAssociationMemberships,
    ContractorHSAndWellbeingEnvironment,
    ContractorSignature,
    ContractorBankDetails,
    ContractorJobOfferCard,
    ContractorJobOfferModal,
  },
})
export default class Contractor extends ContractorManagementHub {
  private contractor: ContractorModel | null = null
  private contractorList: ContractorModel[] = []
  private subcontractors: ContractorModel[] = []
  private getContractorDetailLoading = false
  private getContractorJobsLoading = false
  private getContractorEngineersLoading = false
  private contractorshow = false
  private contractordropdown = true
  private allContractorRatePackageList: ContractorRatePackageModel[] = []
  private contractorAvailability: ContractorTradeAvailabilityModel[] = []
  private isIndependent = false
  private contractorProfileData: ContractorModel | null = null
  private showContractorProfileDialog = false
  private snackbar = false
  private snackbarTimeout = 3000
  private snackbarText = ''
  private selectedJobToOpen = ''
  private activeTab = 'tab-1'
  private isVisitSummaryDataLoading = false
  private coverage: ContractorCoverageModel[] = []
  private contractorBankDetail: BankAccountDetail = new BankAccountDetail()
  private documentDetailList: UploadedContractorDocumentDetail[] = []
  private selectedPreferredContractor = 'All'
  private tempContractorList: ContractorModel[] = []
  private hasAnySubcontractor = false
  private selectedVisitType: string = ContractorVisitTypeEnum[ContractorVisitTypeEnum.AllVisit]
  private showSelectVisitTypeDropdown = true
  private jobId = ''
  private contractorAppointedId = ''

  private created() {
    this.childPage = 'ContractorManagement' // for SignalR
    // if engineer logged in, only load the corresponding contractor
    if (this.isUserRoleContractor) {
      if (!this.selectedContractorId) {
        this.selectedContractorId = Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId
      }
      if (
        this.selectedContractorId &&
        this.selectedContractorId === Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId
      ) {
        this.getContractorDetails(this.selectedContractorId, true)
        this.getContractorJobs(this.selectedContractorId)
        this.getContractorDocumentMetadata(this.selectedContractorId)
      } else {
        // trying to access a different contractor than the one the current user is under, deny access
        return
      }
    } else {
      // if user logged in, try getting the passed in contractor id
      if (this.$route.params.contractorId) {
        this.selectedContractorId = this.$route.params.contractorId
      }
      this.getContractorList()
      this.getAllContractorRatePackages()
    }
    this.appointedContractorHandler() // call method to invoke handler of appointedContractor
    this.contractorGroupAddedHandler()
    this.contractorGroupRemovedHandler()
    this.updateAppointedContractorDetailHandler()
    this.contractorManagementHub.connect() // connect signalR Hub
    this.engineerAvailabilityHandler()
    this.engineerAvailabilityHub.connect()
    eventBus.$emit('clearSearchInput', '', true)
  }

  private mounted() {
    // open job detail popup
    this.selectedJobToOpen =
      this.$route.params.contractorId !== undefined &&
      this.$route.params.contractorAppointedDetailId !== 'financialSummary' &&
      this.$route.params.contractorAppointedDetailId !== undefined
        ? this.$route.params.contractorAppointedDetailId
        : ''
    if (this.selectedJobToOpen) {
      // auto select job tab
      setTimeout(() => {
        this.activeTab = 'tab-1'
      }, 0)
    }

    if (this.$route.params.contractorAppointedDetailId === 'financialSummary') {
      // auto select job tab
      setTimeout(() => {
        this.activeTab = 'tab-8'
      }, 0)
    }

    eventBus.$on('onDocumentDelete', (fileUrl: string) => {
      const index = this.documentDetailList.findIndex((i) => i.documentUrl === fileUrl)
      if (index !== -1) {
        this.documentDetailList.splice(index, 1)
      }
    })

    if (this.$route.name === 'contractorManagement' && this.isUserRoleContractor && this.isSameContractorLoggedIn) {
      this.jobId = this.$route.params.jobId
      this.contractorAppointedId = this.$route.params.contractorAppointedId

      // Set in store for use in child components, leaving old code as it is to prevent breaking changes for now
      const deploymentStore = useDeploymentStore()
      deploymentStore.jobId = this.$route.params.jobId
      deploymentStore.appointmentId = this.$route.params.contractorAppointedId
    } else {
      this.contractor = new ContractorModel()
      this.jobId = ''
      this.contractorAppointedId = ''
    }

    // if engineer is logged in, update profile image in kanban board when changed from AuthHeader
    eventBus.$on('engineerProfileImageUpdated', () => {
      const loggedInUserId = Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.id
      const engineer = this.getEngineers.find((e) => e.id === loggedInUserId)
      if (engineer) {
        engineer.profileImageUrl = Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.profileImageUrl
        engineer.profileImageThumbnailUrl =
          Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.profileImageThumbnailUrl
      }
    })
    // update engineer list
    eventBus.$on('engineerListUpdated', (engineerList: EngineerModel[]) => {
      this.getEngineers = engineerList
    })
  }

  private get getContractor(): ContractorModel | null {
    return this.contractor
  }

  private set getContractor(newValue: ContractorModel | null) {
    this.contractor = newValue
  }

  private get getContractorId(): string {
    return this.getContractor ? this.getContractor.id : ''
  }

  private get isUserRoleContractor(): boolean {
    return Store.Instance.state.SessionDetail.detailRecordType === 'EngineerDetail'
  }

  private get isSameContractorLoggedIn(): boolean {
    return (
      Store.Instance.state.SessionDetail.detailRecord.EngineerDetail.contractorId === this.$route.params.contractorId
    )
  }

  private getLoaderStyle(size: number) {
    return Shared.getLoaderStyle(size)
  }

  private addcontspec() {
    this.contractorshow = true
    this.contractordropdown = true
  }
  private removecontspec(showSelectVisitTypeDropdown = false) {
    this.contractorshow = false
    this.contractordropdown = true
    this.showSelectVisitTypeDropdown = showSelectVisitTypeDropdown
  }
  private disablecontractor() {
    this.contractordropdown = false
    this.showSelectVisitTypeDropdown = false
  }

  private getContractorList() {
    const self = this
    self.getContractorDetailLoading = true
    ContractorController.GetAllContractors()
      .then((res: ContractorModel[]) => {
        self.contractorList = res
        self.getContractorDetailLoading = false
        self.tempContractorList = JSON.parse(JSON.stringify(self.contractorList))
        // set default selected contractor
        if (self.contractorList && self.contractorList.length > 0) {
          const contractorId = self.contractorList[0].id
          if (self.selectedContractorId === '') {
            self.selectedContractorId = contractorId
          }
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor list, please try again', true)
        self.getContractorDetailLoading = false
      })
  }

  private getContractorDetails(contractorId: string, isLoginContractor = false) {
    const self = this
    self.getContractorDetailLoading = true
    ContractorController.GetContractorDetails(contractorId)
      .then((res: ContractorModel | null) => {
        if (res) {
          self.contractor = res
          this.isIndependent = self.contractor.isIndependent
          self.getContractorAvailability = res.contractorAvailability || []
          self.getContractorCoverage = res.coverage || []
          self.contractorBankDetail = res.bankAccountDetail
          this.getSubContractorList(isLoginContractor)
        } else {
          self.contractor = new ContractorModel()
        }
        self.getContractorDetailLoading = false
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading contractor detail, please try again', true)
        self.getContractorDetailLoading = false
      })
  }

  // get sub contractor list
  private getSubContractorList(isLoginContractor: boolean) {
    if (
      this.contractor &&
      this.contractor.subContractors &&
      this.contractor.subContractors.length > 0 &&
      this.isUserRoleContractor
    ) {
      this.hasAnySubcontractor = true
      this.getContractorDetailLoading = true
      let contractorIds: string[] = []
      contractorIds = Object.assign([], this.contractor.subContractors)
      contractorIds.push(this.contractor.id)
      ContractorController.GetSubContractors(contractorIds)
        .then((res: ContractorModel[]) => {
          this.contractorList = res

          // set subcontractors for logged in contractor
          if (isLoginContractor) {
            this.subcontractors = Object.assign([], res)
          }
          this.getContractorDetailLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading sub contractor(s), please try again', true)
          this.getContractorDetailLoading = false
        })
    }
  }

  @Watch('selectedContractorId')
  private contractorIdSelected() {
    this.$store.commit('contractorModule/setSelectedContractorId', this.selectedContractorId)

    if (this.selectedContractorId) {
      this.contractorJobs = []
      this.getContractorDetails(this.selectedContractorId)
      this.getContractorJobs(this.selectedContractorId)
      this.getContractorEngineers(this.selectedContractorId)
      this.getContractorDocumentMetadata(this.selectedContractorId)
      this.updateSignalRGroup()
      this.selectedVisitType = ContractorVisitTypeEnum[ContractorVisitTypeEnum.AllVisit]
      eventBus.$emit('clearSearchInput', '', true)
    }
  }

  private getAllContractorRatePackages() {
    const self = this
    RateController.GetAllContractorRatePackages()
      .then((res: ContractorRatePackageModel[]) => {
        if (res) {
          self.allContractorRatePackageList = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading rate packages, please try again', true)
      })
  }

  private getContractorJobs(contractorId: string) {
    const self = this
    self.getContractorJobsLoading = true
    setTimeout(() => {
      ContractorController.GetAllContractorJobs(contractorId)
        .then((res: ContractorAppointedModel[]) => {
          self.contractorJobs = res
          self.getContractorJobsLoading = false
        })
        .catch((err: any) => {
          eventBus.$emit('errorHandler', 'Error loading contractor job(s), please try again', true)
          self.getContractorJobsLoading = false
        })
    }, 100)
  }

  private onContractorAvailabilityUpdate(tradesAvailability: ContractorTradeAvailabilityModel[]) {
    this.getContractorAvailability = tradesAvailability
    if (this.getContractor) {
      this.getContractor.contractorAvailability = tradesAvailability
    }
  }

  private get getContractorAvailability(): ContractorTradeAvailabilityModel[] {
    return this.contractorAvailability
  }

  private set getContractorAvailability(newValue: ContractorTradeAvailabilityModel[]) {
    this.contractorAvailability = newValue
  }

  private get getEngineers() {
    const self = this
    if (self.engineers.length > 0) {
      self.engineers.forEach((engineer: EngineerModel) => {
        engineer.name = engineer.firstName + ' ' + engineer.lastName
      })
    }
    return self.engineers
  }

  private set getEngineers(newValue: EngineerModel[]) {
    this.engineers = newValue
  }

  private async getContractorEngineers(contractorId: string) {
    this.getContractorEngineersLoading = true
    const deploymentStore = useDeploymentStore()

    try {
      await deploymentStore.retrieveEngineers(contractorId)
      this.engineers = deploymentStore.engineers
    } catch {
      eventBus.$emit('errorHandler', 'Error loading engineers, please try again', true)
    }

    this.getContractorEngineersLoading = false
  }

  private get getTotalEngineersLength() {
    return this.getEngineers.length
  }

  private get getFirstEngineerDetails(): EngineerModel | null {
    if (this.getEngineers) {
      return this.getEngineers[0]
    }
    return null
  }

  private get getJobContractors() {
    return this.contractorJobs
  }

  private isIndependentContractor(isIndependent: boolean, contractor: ContractorModel) {
    this.isIndependent = isIndependent
    if (isIndependent) {
      this.getContractorEngineers(this.selectedContractorId)
    }
    if (contractor) {
      this.getContractor = contractor
      const index = this.contractorList.findIndex((e) => e.id === this.selectedContractorId)
      if (index !== -1) {
        this.contractorList[index] = contractor
      }
      const tempListIndex = this.tempContractorList.findIndex((e) => e.id === this.selectedContractorId)
      if (tempListIndex !== -1) {
        this.tempContractorList[tempListIndex] = contractor
      }
    }
  }

  private addContractorClick() {
    this.contractorProfileData = new ContractorModel()
    this.contractorProfileData.country = 'England'
    this.showContractorProfileDialog = true
  }

  private saveContractor(isUpdated, contractor: ContractorModel) {
    if (isUpdated && contractor !== null) {
      // select newly created contractor
      this.contractorList.push(contractor)
      this.tempContractorList.push(contractor)
      this.selectedContractorId = contractor.id
      // show message for newly created contractor
      this.snackbarText = 'Contractor created successfully.'
      this.showContractorProfileDialog = false
      this.snackbar = true
    }
  }

  private get getContractorCoverage(): ContractorCoverageModel[] {
    return this.coverage
  }

  private set getContractorCoverage(newValue: ContractorCoverageModel[]) {
    this.coverage = Shared.SortData(newValue, 'postcode')
  }

  private onCoverageChange(coverage: ContractorCoverageModel[]) {
    this.getContractorCoverage = coverage
  }

  private get getContractorBankDetail(): BankAccountDetail {
    return this.contractorBankDetail ? this.contractorBankDetail : new BankAccountDetail()
  }

  private set getContractorBankDetail(newValue: BankAccountDetail) {
    this.contractorBankDetail = newValue
  }

  private onContractorBankDetailUpdate(bankDetail: BankAccountDetail) {
    this.getContractorBankDetail = bankDetail
  }

  private getContractorDocumentMetadata(contractorId: string) {
    const self = this
    ContractorController.GetContractorDocumentMetadata(contractorId)
      .then((res: UploadedContractorDocumentDetail[] | null) => {
        if (res) {
          self.documentDetailList = res
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading documents, please try again', true)
      })
  }

  private onSaveTradeMembershipDetails(contractor: ContractorModel, isDocumentUploaded: boolean) {
    if (this.getContractor) {
      this.getContractor.isGasSafeRegistered = contractor.isGasSafeRegistered
      this.getContractor.gasSafeRegistrationNumber = contractor.gasSafeRegistrationNumber
      this.getContractor.directEmployedHEWorker = contractor.directEmployedHEWorker
      this.getContractor.subContractedHEWorker = contractor.subContractedHEWorker
      this.getContractor.hasLicenceToCarryWaste = contractor.hasLicenceToCarryWaste
      if (isDocumentUploaded) {
        this.getContractorDocumentMetadata(this.selectedContractorId)
      }
    }
  }

  private onSaveHealthSafetyWellbeingEnvironment(
    healthSafetyWellbeingEnvironmentModel: HealthSafetyWellbeingEnvironment,
    isDocumentUploaded: boolean
  ) {
    if (this.getContractor) {
      this.getContractor.healthSafetyWellbeingEnvironment = healthSafetyWellbeingEnvironmentModel
      if (isDocumentUploaded) {
        this.getContractorDocumentMetadata(this.selectedContractorId)
      }
    }
  }

  private onSaveContractorSignatureDetails(signature: SignatureModel) {
    if (this.getContractor) {
      this.getContractor.signature = signature
    }
  }

  private get getPreferredContractorOptions(): IPreferredContractor[] {
    const preferredContractors = JSON.parse(JSON.stringify(PreferredContractors))
    preferredContractors.unshift({ id: 'All', description: 'All' })
    return preferredContractors
  }

  @Watch('selectedPreferredContractor')
  private selectedPreferredContractorChange() {
    if (this.selectedPreferredContractor === 'All') {
      this.contractorList = this.tempContractorList
    } else {
      this.contractorList = this.tempContractorList.filter(
        (e) => e.preferredContractor === this.selectedPreferredContractor
      )
      if (
        this.contractorList.length > 0 &&
        this.contractorList[0].id &&
        this.selectedContractorId !== this.contractorList[0].id
      ) {
        this.selectedContractorId = this.contractorList[0].id
      }
    }
  }

  private get getVisitTypes(): IDropdownType[] {
    return [
      {
        id: 0,
        description: ContractorVisitTypeEnum[ContractorVisitTypeEnum.AllVisit],
        alterText: 'All',
      },
      {
        id: 1,
        description: ContractorVisitTypeEnum[ContractorVisitTypeEnum.FirstVisit],
        alterText: 'First',
      },
      {
        id: 2,
        description: ContractorVisitTypeEnum[ContractorVisitTypeEnum.ReturnVisit],
        alterText: 'Return',
      },
      {
        id: 2,
        description: ContractorVisitTypeEnum[ContractorVisitTypeEnum.VisitWithoutETA],
        alterText: 'Without ETA',
      },
    ]
  }

  private filterJobsBySelectedVisitType(visitType: string) {
    eventBus.$emit('clearSearchInput', '', true)
    eventBus.$emit('onVisitTypeChange', visitType)
  }

  private showSnackBarMessage(message: string) {
    this.snackbarText = message
    this.snackbar = true
  }

  private closeJobOfferDialog() {
    this.jobId = ''
    this.contractorAppointedId = ''
  }

  public get isLegacyAutoDeploymentEnabled(): boolean {
    return !this.$ld.variation('fnol-401-multiple-contractor-notifications')
  }
}
</script>

<style scoped>
.contractor-section >>> .v-tabs__bar,
.contractor-section >>> .v-window {
  display: inline-block;
  vertical-align: top;
}
.contractor-section >>> .v-tabs__item {
  display: flex;
}
.contractor-section >>> .v-window {
  width: calc(100% - 135px);
  display: block;
  margin-left: auto;
  padding: 0px 16px !important;
}
.contractor-section >>> .v-tabs .v-tabs__bar {
  position: fixed;
  left: 0;
  width: 135px;
  z-index: 1;
  overflow: auto;
  top: 60px;
}
.contractor-section >>> .v-tabs .v-tabs__bar .tab-wrap {
  height: calc(100vh - 80px);
  padding: 0 15px 90px 0;
  margin-top: 25px;
}
.contractor-section >>> .v-tabs__bar > i {
  display: none;
}
.contractor-section >>> .v-tabs__wrapper {
  overflow: visible;
  display: block;
  margin: 0;
  padding: 0;
}
.contractor-section >>> .v-tabs__container {
  text-align: center;
  white-space: inherit;
  position: static;
  display: block;
  transform: inherit !important;
  height: auto;
}
.contractor-section >>> .v-tabs__div {
  width: 100%;
  margin-bottom: 10px;
  min-width: auto;
  height: auto;
}
.contractor-section {
  background-color: #eeeeee;
  height: 100%;
  position: relative;
}
.contractor-section >>> .v-tabs__item--active,
.contractor-section >>> .v-tabs__item--active i {
  color: #e91e63;
}
.contractor-section >>> .v-tabs__item--active svg path,
.contractor-section >>> .v-tabs__item--active svg polygon {
  fill: #e91e63;
}
.contractor-section.mid-section-content,
.contractor-section >>> .v-tabs__bar {
  background-color: #eeeeee;
}
.contractor-section >>> .v-window-item {
  transition: none !important;
}
.mid-section {
  width: 80%;
  margin: auto;
}
.contractor-mgm-search {
  position: absolute;
  top: 15px;
  right: 15px;
  min-width: 300px;
  z-index: 1;
  padding-right: 160px;
  text-align: right;
}
.contractor-mgm-search .add-contractor-btn {
  position: absolute;
  top: 0px;
  right: 0px;
}
.showcontractor >>> .v-window {
  margin-top: 55px;
}
.engineer-img {
  width: 25px;
  margin-bottom: 5px;
}
.badge-img {
  background: #fff;
  padding: 6px 0px 0px;
  -webkit-box-shadow: 2px 2px 5px 0px #e4e4e4;
  box-shadow: 2px 2px 5px 0px #e4e4e4;
  margin-bottom: 10px;
  margin-left: 14px;
  margin-top: 0px !important;
}
.badge-img img {
  width: 80px;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item .menu-images {
  padding-bottom: 10px;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item .active-menu-icon {
  display: none;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item--active .menu-icon {
  display: none;
}
.contractor-section >>> .v-tabs .v-tabs__div > .v-tabs__item--active .active-menu-icon {
  display: block;
}
.contractor-section >>> .v-tabs__bar {
  position: fixed;
}
.contractor-section >>> .v-tabs__items {
  left: 115px;
}
.contractor-specialization-content {
  height: calc(100vh - 155px);
}
.contractor-mgm-search .contractor_status,
.contractor-mgm-search .contractor_list,
.contractor-mgm-search .visit-type {
  width: 300px;
  display: inline-block;
}
.contractor-mgm-search .visit-type-space {
  margin-right: 150px !important;
}
.contractor-search-cn {
  padding-right: 0px;
}
@media (max-width: 1400px) {
  .contractor-profile-content >>> .profile-sec {
    max-width: 80% !important;
    flex-basis: 80%;
    margin-left: 10% !important;
  }
}
@media (max-width: 1300px) {
  .contractor-mgm-search .contractor_status,
  .contractor-mgm-search .contractor_list,
  .contractor-mgm-search .visit-type {
    width: 245px;
  }
  .contractor-mgm-search .visit-type-space {
    margin-right: 95px !important;
  }
}
@media (max-width: 1200px) {
  .mid-section {
    width: 94%;
    margin: auto;
  }
  .contractor-profile-content >>> .profile-sec {
    max-width: 100% !important;
    flex-basis: 100%;
    margin-left: 0% !important;
  }
}
@media (max-width: 1100px) {
  .contractor-mgm-search .contractor_status,
  .contractor-mgm-search .contractor_list,
  .contractor-mgm-search .visit-type {
    width: 220px;
  }
  .contractor-mgm-search .visit-type-space {
    margin-right: 70px !important;
  }
}
</style>
