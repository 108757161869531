<!-- THIS COMPONENT IS DEPRECATED -->
<template>
  <div>
    <v-layout wrap>
      <v-flex xs12>
        <!-- Auto Deploy -->
        <v-dialog
          v-model="showOfferedJobDetails"
          content-class="v-dialog--scrollable"
          :max-width="jobOfferedActionTimeExpired ? 480 : 800"
          persistent
        >
          <v-card>
            <v-toolbar card dark color="primary">
              <v-toolbar-title>Offered Job Details</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon class="close-btn" @click="showOfferedJobDetails = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-card-text class="add-dialog-content scroll-content-dialog pa-0">
              <v-layout v-if="!jobOfferedActionTimeExpired" wrap pa-2>
                <v-flex xs12 px-2 py-1>
                  <div class="mb-2">
                    <label><b>Job:</b></label>
                    <span>&nbsp;{{ contractorOfferedJobAppointedModelTemp.jobId }}</span>
                  </div>
                  <div class="mb-2">
                    <label><b>Post Code:</b></label>
                    <span>&nbsp;{{ contractorOfferedJobAppointedModelTemp.postcode }}</span>
                  </div>
                  <div class="mb-2">
                    <label><b>Client Name:</b></label>
                    <span>&nbsp;{{ contractorOfferedJobAppointedModelTemp.customerName }}</span>
                  </div>
                  <div class="mb-2">
                    <label><b>Emergency:</b></label>
                    <span>&nbsp;{{ contractorOfferedJobModel.emergencyTypeDescription }}</span>
                    <span v-if="contractorOfferedJobModel.emergencyDetailDescription">
                      &nbsp;({{ contractorOfferedJobModel.emergencyDetailDescription }})
                    </span>
                  </div>
                  <div class="mb-2">
                    <label><b>Address:</b></label>
                    <span>&nbsp;{{ contractorOfferedJobAppointedModelTemp.address }}</span>
                  </div>
                  <div v-if="contractorOfferedJobModel.engineerSiteSLA">
                    <label><b>SLA cut off time :</b></label>
                    <span>&nbsp;{{ getFormattedDate(contractorOfferedJobModel.engineerSiteSLA) }}</span>
                  </div>
                </v-flex>
                <v-flex pl-2 xs6 pr-2>
                  <DateTimePicker
                    ref="ETAfromdateTimePicker"
                    :date-time.sync="acceptAutoDeploymentJobModel.etaFrom"
                    :is-static-location="false"
                    place-holder-text="ETA From"
                    :is-validation-required="true"
                    :allowed-minutes-step="true"
                    :allowed-dates="getDatesFromCustomerAvailability"
                    :min-date="minETADate"
                    :min-time="minETATime"
                    :max-date="maxETAFromDate"
                    :max-time="maxETAFromTime"
                  />
                </v-flex>
                <v-flex xs6 pl-2 pr-2>
                  <DateTimePicker
                    ref="ETATodateTimePicker"
                    :date-time.sync="acceptAutoDeploymentJobModel.etaTo"
                    :is-static-location="false"
                    place-holder-text="ETA To"
                    :is-validation-required="true"
                    :allowed-minutes-step="true"
                    :min-date="minETAToDate"
                    :min-time="minETAToTime"
                    :max-date="maxETAToDate"
                    :max-time="maxETAToTime"
                    :allowed-dates="getDatesFromCustomerAvailability"
                  />
                </v-flex>
                <v-flex v-if="isWrongDateRange || isWrongTimeRange" xs12 mb-2>
                  <span v-if="isWrongDateRange" class="error--text">To date must be greater than From date.</span>
                  <span v-if="isWrongTimeRange" class="error--text">
                    Customer is not available within contractor ETA.
                  </span>
                </v-flex>
                <v-flex v-if="showDeploymentMessage" xs12 mb-2>
                  <span class="warning--text">You are not allowed to deploy outside the SLA cut off time.</span>
                </v-flex>
                <v-flex xs12 class="ma-2">
                  <label><b>Customer's Availability:</b></label>
                  <Availability :customer-availability="customerAvailability"></Availability>
                </v-flex>
                <v-flex class="pa-2 ma-2 grey lighten-3">
                  <b>
                    Time left to accept job
                    <b class="secondary--text">
                      <v-icon small color="secondary">access_time</v-icon>
                      {{ contractorJobOfferTimerCounter }}
                    </b>
                  </b>
                </v-flex>
              </v-layout>
              <v-layout v-else class="job-action-time-expired">
                <v-flex xs12 px-2 py-1>
                  <h3>Time duration to accept job has been expired!</h3>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions v-if="!jobOfferedActionTimeExpired" class="text-xs-right pa-3">
              <v-spacer></v-spacer>
              <v-btn
                class="red white--text"
                :disabled="isAcceptJobLoading || isRejectJobLoading"
                @click="rejectOfferedJobConfirmation"
              >
                Reject Job
              </v-btn>
              <v-btn
                class="green white--text acceptJob-btn"
                :loading="isAcceptJobLoading"
                :disabled="isAcceptJobLoading || isRejectJobLoading || isWrongDateRange || isWrongTimeRange"
                @click.native="acceptOfferedJob"
              >
                Accept Job
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Job Accept -->
        <v-dialog
          v-if="showEngineerSelectionDialog"
          v-model="showEngineerSelectionDialog"
          content-class="v-dialog--scrollable"
          max-width="600"
          persistent
        >
          <v-card>
            <v-toolbar card dark color="primary">
              <v-toolbar-title>Assign Engineer</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon class="close-btn" @click.native="showEngineerSelectionDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-card-text class="add-dialog-content scroll-content-dialog pa-0">
              <v-layout wrap pa-2>
                <v-flex xs12 px-2 py-1>
                  <v-select
                    v-model="selectedEngineerId"
                    :loading="getContractorEngineersLoading"
                    :items="getTradeWiseEngineers(contractorOfferedJobAppointedModelTemp.tradeId)"
                    label="Select Engineer"
                    item-text="name"
                    item-value="id"
                    persistent-hint
                    class="hide-option"
                    @change="assignEngineerToJob(contractorOfferedJobAppointedModelTemp, $event)"
                    @click.native="onClickAssignEngineer(contractorOfferedJobAppointedModelTemp)"
                  >
                    <template slot="selection" slot-scope="data">
                      <v-avatar>
                        <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                        <img v-else src="/img/unknownuser.png" />
                      </v-avatar>
                      &nbsp;
                      {{ data.item.firstName + ' ' + data.item.lastName }}
                    </template>
                    <template slot="item" slot-scope="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-tile-content v-text="data.item"></v-list-tile-content>
                      </template>
                      <template v-else>
                        <v-list-tile-avatar>
                          <img v-if="data.item.profileImageThumbnailUrl" :src="data.item.profileImageThumbnailUrl" />
                          <img v-else src="/img/unknownuser.png" />
                        </v-list-tile-avatar>
                        <v-list-tile-content>
                          <v-list-tile-title>
                            {{ data.item.firstName + ' ' + data.item.lastName }}
                          </v-list-tile-title>
                          <v-list-tile-sub-title>
                            {{ data.item.email }}
                          </v-list-tile-sub-title>
                        </v-list-tile-content>
                      </template>
                    </template>
                  </v-select>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-xs-right pa-3">
              <v-spacer></v-spacer>
              <!-- <v-btn flat color="primary" @click.native="showEngineerSelectionDialog = false">Close</v-btn> -->
              <v-btn class="primary text--white" @click.native="showEngineerSelectionDialog = false">Close</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- Reject Job -->
        <v-dialog
          v-if="showRejectReasonSelectionDialog"
          v-model="showRejectReasonSelectionDialog"
          content-class="v-dialog--scrollable"
          max-width="600"
          persistent
        >
          <v-card>
            <v-toolbar card dark color="primary">
              <v-toolbar-title>Reject Job</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon class="close-btn" @click.native="showRejectReasonSelectionDialog = false">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider />
            <v-card-text class="add-dialog-content scroll-content-dialog pa-0">
              <v-layout wrap pa-3>
                <v-flex>
                  <v-select
                    v-model="jobRejectReason"
                    v-validate="'required'"
                    :items="jobRejectReasonList"
                    item-text="description"
                    item-value="id"
                    label="Select cancellation reason for job"
                    required
                    class="required"
                    :loading="jobRejectReasonListLoading"
                    data-vv-scope="frmCancellationReason"
                    data-vv-name="Cancel Job Reason"
                    :error-messages="errors.collect('Cancel Job Reason')"
                  ></v-select>
                </v-flex>
                <v-flex xs12>
                  <v-textarea v-model="jobRejectDescription" label="Description"></v-textarea>
                </v-flex>
              </v-layout>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions class="text-xs-right pa-3">
              <v-spacer></v-spacer>
              <v-btn flat color="primary" @click.native="showRejectReasonSelectionDialog = false">Cancel</v-btn>
              <v-btn
                class="primary text--white"
                :loading="isRejectJobLoading"
                :disabled="isRejectJobLoading"
                @click="rejectedJob"
              >
                Submit
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { RecordType, AutoDeploymentTypeEnum, JobVisitStatus } from '@/common/enums'
import MasterRecord from '@/models/MasterRecord'
import MasterRecordController from '@/api/masterRecordController'
import store from '@/store'
import AddRequestForCancelJobModel from '@/models/requests/AddRequestForCancelJobModel'
import moment, { Moment, isMoment } from 'moment'
import DateTimePicker from '@/components/DateTimePicker.vue'
import Job from '@/models/Job'
import storeGetters from '@/storeGetters'
import Emergency from '../models/Emergency'
import EngineerAvailabilityModel from '../models/contractor/EngineerAvailabilityModel'
import UpdateContractorAppointedDetail from '../models/requests/UpdateContractorAppointedDetail'
import ContractorOfferedJobModel from '../models/ContractorOfferedJobModel'
import CustomerAvailabilityModel from '../models/claim/CustomerAvailabilityModel'
import TimeSlot from '../models/claim/TimeSlot'
import ContractorRejectAutoDeploymentJob from '../models/contractor/ContractorRejectAutoDeploymentJobModel'
import ContractorAcceptAutoDeploymentJob from '../models/contractor/ContractorAcceptAutoDeploymentJobModel'
import Availability from '@/components/Availability.vue'
import ContractorAppointedModel from '../models/claim/ContractorAppointedModel'
import ContractorController from '../api/contractorController'
import Shared from '../common/shared'
import Store from '@/store'
import EngineerController from '../api/engineerController'
import EngineerModel from '../models/contractor/EngineerModel'
import eventBus from '@/common/bus'

@Component({
  components: { DateTimePicker, Availability },
})
/**
 * @deprecated This component is deprecated.
 * Please use ContractorJobOfferModal instead.
 */
export default class ContractorJobOfferCard extends Vue {
  @Prop() private jobId: string
  @Prop() private contractorAppointedId: string
  @Prop() private getEngineers: EngineerModel[]
  private showOfferedJobDetails = false
  private showEngineerSelectionDialog = false
  private showRejectReasonSelectionDialog = false
  private jobRejectDescription = ''
  private jobRejectReason = ''
  private jobRejectReasonList: MasterRecord[] = []
  private jobRejectReasonListLoading = false
  private jobOfferedActionTimeExpired = false
  private allowToGetOfferedJobDetails = false
  private contractorOfferedJobAppointedModelTemp: ContractorAppointedModel = new ContractorAppointedModel()
  private contractorOfferedJobModel: ContractorOfferedJobModel = new ContractorOfferedJobModel()
  private isAcceptJobLoading = false
  private isRejectJobLoading = false
  private selectedEngineerId = ''
  private timeLeftToAcceptJob = 0
  private customerAvailability: CustomerAvailabilityModel = new CustomerAvailabilityModel()
  private acceptAutoDeploymentJobModel: ContractorAcceptAutoDeploymentJob = new ContractorAcceptAutoDeploymentJob()
  private isWrongDateRange = false
  private contractorJobOfferTimer: any = null
  private contractorJobOfferTimerCounter: any = null
  private isTimerStarted = false
  private isWrongTimeRange = false
  private showDeploymentMessage = false
  private minETADate = ''
  private minETATime = ''
  private maxETAFromDate = ''
  private maxETAFromTime = ''
  private maxETAToDate = ''
  private maxETAToTime = ''
  private minETAToDate = ''
  private minETAToTime = ''
  private etaFromOldValue: moment.Moment | null = null
  private etaToOldValue: moment.Moment | null = null
  private tempMaxTime = ''

  private created() {
    if (this.jobId && this.contractorAppointedId) {
      this.isTimerStarted = false
      this.getOfferedJobDetails(this.jobId, this.contractorAppointedId)
    }
  }

  private getOfferedJobDetails(jobId: string, contractorAppointedId: string) {
    ContractorController.GetContractorAppointedDetail(jobId, contractorAppointedId)
      .then((res: ContractorOfferedJobModel | null | string) => {
        if (typeof res === 'string') {
          this.showOfferedJobDetails = true
          this.jobOfferedActionTimeExpired = true
          // emit
        } else if (res) {
          this.contractorOfferedJobModel = res
          this.contractorOfferedJobAppointedModelTemp = this.contractorOfferedJobModel.contractorAppointedModel
          if (this.contractorOfferedJobModel.timeDuration > 0) {
            this.timeLeftToAcceptJob = this.contractorOfferedJobModel.timeDuration
            this.startTimer()
          }
          this.customerAvailability = this.contractorOfferedJobModel.customerAvailability
          this.setEta()
          this.showOfferedJobDetails = true
        } else {
          this.showOfferedJobDetails = false
          this.$emit('showSnackBarMessage', 'Link has expired.')
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error loading job detail, please try again', true)
        this.showOfferedJobDetails = false
      })
  }

  private startTimer() {
    this.contractorJobOfferTimer = setInterval(() => {
      this.timeLeftToAcceptJob--
      if (this.timeLeftToAcceptJob > 0) {
        this.jobOfferedActionTimeExpired = false
        this.isTimerStarted = true
        this.contractorJobOfferTimerCounter = Shared.formatDuration(Math.round(this.timeLeftToAcceptJob))
      } else {
        this.contractorJobOfferTimerCounter = Shared.formatDuration(0)
        clearInterval(this.contractorJobOfferTimer)
        if (this.isTimerStarted) {
          this.jobOfferedActionTimeExpired = true
        }
      }
    }, 1000)
  }

  private rejectOfferedJobConfirmation() {
    Shared.confirmationPopup.open(
      'Are you sure you want to reject the job?',
      '',
      '',
      'No',
      'Yes',
      this,
      'getCancelJobReasonList',
      null
    )
  }

  private getCancelJobReasonList(): void {
    this.jobRejectReason = ''
    this.jobRejectDescription = ''
    const recordType: string = RecordType[RecordType.ContractorUnavailableReason]
    MasterRecordController.GetMasterRecords(recordType)
      .then((res: MasterRecord[]) => {
        if (res) {
          this.jobRejectReasonList = res.filter((e) => e.isDeleted === false)
        }
        this.jobRejectReasonListLoading = false
        this.showRejectReasonSelectionDialog = true
      })
      .catch((e) => {
        this.jobRejectReasonListLoading = false
        this.$emit('showSnackBarMessage', 'No cancellation reasons found. Please try again.')
      })
  }

  private rejectedJob() {
    this.validateRejectReason('frmCancellationReason')
      .then((result: boolean) => {
        if (result) {
          this.isRejectJobLoading = true
          const rejectAutoDeploymentJobModel: ContractorRejectAutoDeploymentJob =
            new ContractorRejectAutoDeploymentJob()
          rejectAutoDeploymentJobModel.jobId = this.contractorOfferedJobAppointedModelTemp.jobId
          rejectAutoDeploymentJobModel.contractorAppointedId = this.contractorOfferedJobAppointedModelTemp.id
          rejectAutoDeploymentJobModel.hasAcceptJob = false
          rejectAutoDeploymentJobModel.reasonType = this.jobRejectReason
          rejectAutoDeploymentJobModel.reasonDescription = this.jobRejectDescription
          ContractorController.ContractorRejectAutoDeploymentJob(rejectAutoDeploymentJobModel)
            .then((res) => {
              if (res) {
                this.resetAllTheDialogData()
              }
              this.showRejectReasonSelectionDialog = false
              this.$emit('showSnackBarMessage', 'Offered job has been rejected.')
              this.isRejectJobLoading = false
            })
            .catch((err: any) => {
              this.isRejectJobLoading = false
              eventBus.$emit('errorHandler', 'Error submitting Reject AutoDeployment request, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateRejectReason(frmValidation: string): Promise<boolean> {
    const result: boolean = await this.$validator.validateAll(frmValidation)
    // set focus to non validate field
    if (!result) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    return result
  }

  private acceptOfferedJob() {
    this.isWrongDateRange = false
    this.validateDates()
      .then((result: boolean) => {
        if (result && !this.isWrongDateRange && !this.isWrongTimeRange) {
          this.isAcceptJobLoading = true
          this.acceptAutoDeploymentJobModel.jobId = this.contractorOfferedJobAppointedModelTemp.jobId
          this.acceptAutoDeploymentJobModel.contractorAppointedId = this.contractorOfferedJobAppointedModelTemp.id
          this.acceptAutoDeploymentJobModel.hasAcceptJob = false
          ContractorController.ContractorAcceptAutoDeploymentJob(this.acceptAutoDeploymentJobModel)
            .then((res) => {
              if (res) {
                this.showOfferedJobDetails = false
                this.showEngineerSelectionDialog = true
              }
              this.isAcceptJobLoading = false
            })
            .catch((err: any) => {
              this.isAcceptJobLoading = false
              eventBus.$emit('errorHandler', 'Error submitting Accept AutoDeployment request, please try again', true)
            })
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  private async validateDates(): Promise<boolean> {
    const etaFromDateTimePicker = this.$refs.ETAfromdateTimePicker as DateTimePicker
    const etaToDateTimePicker = this.$refs.ETATodateTimePicker as DateTimePicker
    const etaFromDateTimePickerResult: boolean = await etaFromDateTimePicker.$validator.validateAll()
    const etaToDateTimePickerResult: boolean = await etaToDateTimePicker.$validator.validateAll()
    if (!etaFromDateTimePickerResult) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }
    if (
      this.acceptAutoDeploymentJobModel.etaFrom &&
      this.acceptAutoDeploymentJobModel.etaTo &&
      this.acceptAutoDeploymentJobModel.etaFrom > this.acceptAutoDeploymentJobModel.etaTo
    ) {
      this.isWrongDateRange = true
    }
    if (etaFromDateTimePickerResult === false) {
      return etaFromDateTimePickerResult
    } else {
      return etaToDateTimePickerResult
    }
  }

  private getTradeWiseEngineers(tradeId: number) {
    if (this.getEngineers.length > 0) {
      const engineerList: EngineerModel[] = this.getEngineers.filter((e) => e.engineerAvailability !== null)
      return engineerList.filter(
        (e: EngineerModel) =>
          e.engineerAvailability.findIndex((t: EngineerAvailabilityModel) => t.tradeId === tradeId) > -1 ||
          e.id === null
      )
    } else {
      return []
    }
  }

  private onClickAssignEngineer(offeredJobDetails: ContractorAppointedModel) {
    const engineers: any = this.getTradeWiseEngineers(offeredJobDetails.tradeId)
    engineers.forEach((engineer) => {
      if (engineer.id === null) {
        engineer.disabled = false
      } else {
        const isEngineerAvailable = this.checkEngineerAvailability(
          offeredJobDetails.etaFrom,
          offeredJobDetails.etaTo,
          engineer
        )
        engineer.disabled = !isEngineerAvailable // set disabled property based on engineer availability
      }
    })
  }

  private assignEngineerToJob(offeredJobDetails: ContractorAppointedModel, event: string) {
    const isEngineerAvailable = this.checkEngineerAvailability(
      offeredJobDetails.etaFrom,
      offeredJobDetails.etaTo,
      event
    ) // check engineer availability while assigning Job
    if (!isEngineerAvailable) {
      return
    }
    const engineerid: any = offeredJobDetails.engineerId
    const previouslyAssignedEngineerId = engineerid && typeof engineerid === 'object' ? engineerid.id : engineerid
    if (
      (event === null && (previouslyAssignedEngineerId === '' || previouslyAssignedEngineerId === null)) ||
      previouslyAssignedEngineerId === event
    ) {
      return
    }
    const updateContractorAppointed = new UpdateContractorAppointedDetail(offeredJobDetails.id, offeredJobDetails.jobId)
    updateContractorAppointed.engineerId = event
    updateContractorAppointed.status = JobVisitStatus[JobVisitStatus.Accepted]
    EngineerController.AssignEngineerToJob(updateContractorAppointed)
      .then((res: boolean) => {
        if (res) {
          offeredJobDetails.status = updateContractorAppointed.status
        }
      })
      .catch((err: any) => {
        eventBus.$emit('errorHandler', 'Error assigning job to an engineer, please try again', true)
      })
  }

  private checkEngineerAvailability(etaFrom: moment.Moment | null, etaTo: moment.Moment | null, engineer: any) {
    // check engineer availability compared to job eta.
    let isEngineerAvailable = false
    if (etaFrom && etaTo && engineer) {
      if (
        engineer.outsideToDate &&
        engineer.outsideFromDate &&
        engineer.outsideToDate.isValid() &&
        engineer.outsideFromDate.isValid()
      ) {
        const etaFromDate: string = etaFrom.toISOString().slice(0, 10)
        const outsideFromDate: string = engineer.outsideFromDate.toISOString().slice(0, 10)
        const outsideToDate: string = engineer.outsideToDate.toISOString().slice(0, 10)
        if (etaFromDate === outsideFromDate || etaFromDate === outsideToDate) {
          isEngineerAvailable = false
        } else {
          isEngineerAvailable = etaFrom.isBetween(engineer.outsideFromDate, engineer.outsideToDate) ? false : true
        }
      } else {
        isEngineerAvailable = true
      }
    }
    return isEngineerAvailable
  }

  private resetAllTheDialogData() {
    this.jobOfferedActionTimeExpired = false
    this.showEngineerSelectionDialog = false
    this.showRejectReasonSelectionDialog = false
    this.showOfferedJobDetails = false
    this.$emit('closeJobOfferDialog')
  }

  private get getDatesFromCustomerAvailability(): string[] {
    // Only date availability covered, time is not included
    const dateFormat: string = Shared.dateFormatForDatePicker
    const allowedDates: string[] = []
    const timeSlots = this.customerAvailability.timeSlot || []
    if (timeSlots.length > 0) {
      const firstDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 1)
      if (firstDateTimeSlots.length > 0) {
        Shared.SortData(firstDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(firstDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      const secondDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 2)
      if (secondDateTimeSlots.length > 0) {
        Shared.SortData(secondDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(secondDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      const thirdDateTimeSlots = this.customerAvailability.timeSlot.filter((a: TimeSlot) => a.availabilityOrder === 3)
      if (thirdDateTimeSlots.length > 0) {
        Shared.SortData(thirdDateTimeSlots, 'startTime')
        allowedDates.push(Shared.getFormatedDate(moment(thirdDateTimeSlots.map((c) => c.startTime)[0]), dateFormat))
      }
      return allowedDates
    }
    return []
  }

  private getFormattedDate(date: moment.Moment) {
    return Shared.getFormatedDate(moment(date), Store.Instance.state.Environment.DateTimeFormat)
  }

  private setEta() {
    this.acceptAutoDeploymentJobModel.etaFrom = Shared.roundedMinutes(moment(), 15)
    this.minETADate = moment().format(DateTimePicker.DATE_FORMAT)
    this.minETATime = moment().format(DateTimePicker.TIME_FORMAT)
    if (this.contractorOfferedJobModel.engineerSiteSLA) {
      const etaToDate: moment.Moment = moment(this.contractorOfferedJobModel.engineerSiteSLA)
      const roundedETAFromMinute = Math.ceil(moment(etaToDate).minute() / -15) * -15
      this.acceptAutoDeploymentJobModel.etaTo = etaToDate.minute(roundedETAFromMinute).second(0)
      if (this.isAvailabilityWithinSLATime()) {
        this.maxETAFromDate = moment(this.contractorOfferedJobModel.engineerSiteSLA).format(DateTimePicker.DATE_FORMAT)
        this.maxETAFromTime = moment(this.acceptAutoDeploymentJobModel.etaTo).format(DateTimePicker.TIME_FORMAT)
        this.maxETAToTime = this.maxETAFromTime
        this.maxETAToDate = moment(this.contractorOfferedJobModel.engineerSiteSLA).format(DateTimePicker.DATE_FORMAT)
        this.showDeploymentMessage = true
      }
      this.tempMaxTime = this.maxETAToTime
    } else {
      // engineerSiteSLA hrs not added for emergency
      this.acceptAutoDeploymentJobModel.etaTo = Shared.roundedMinutes(moment(), 15)
    }
  }

  @Watch('acceptAutoDeploymentJobModel.etaFrom')
  @Watch('acceptAutoDeploymentJobModel.etaTo')
  private onETAChange(newValue: moment.Moment | null, oldValue: moment.Moment | null) {
    this.isWrongDateRange = false
    this.isWrongTimeRange = false
    const slaCutOffTime = moment(this.contractorOfferedJobModel.engineerSiteSLA)
    if (this.acceptAutoDeploymentJobModel.etaFrom && this.acceptAutoDeploymentJobModel.etaTo) {
      const etaFromDate = this.acceptAutoDeploymentJobModel.etaFrom
      const etaToDate = this.acceptAutoDeploymentJobModel.etaTo
      const emergencyCreatedDate = moment()
      if (emergencyCreatedDate) {
        // set minETATime based on date selection
        if (
          moment(etaFromDate).format(DateTimePicker.DATE_FORMAT) ===
          moment(emergencyCreatedDate).format(DateTimePicker.DATE_FORMAT)
        ) {
          this.minETATime = moment(emergencyCreatedDate).format(DateTimePicker.TIME_FORMAT)
        } else {
          this.minETATime = ''
        }
        // set maxETAToTime based on date selection
        this.maxETAToTime =
          moment(etaToDate).format(DateTimePicker.DATE_FORMAT) === this.maxETAToDate ? this.tempMaxTime : ''
      }
      if (this.contractorOfferedJobModel.engineerSiteSLA) {
        // if customer available within SLA time, set max Time
        if (this.isAvailabilityWithinSLATime()) {
          if (
            moment(etaFromDate).format(DateTimePicker.DATE_FORMAT) ===
            moment(this.contractorOfferedJobModel.engineerSiteSLA).format(DateTimePicker.DATE_FORMAT)
          ) {
            this.maxETAFromTime = moment(this.contractorOfferedJobModel.engineerSiteSLA).format(
              DateTimePicker.TIME_FORMAT
            )
          } else {
            this.maxETAFromTime = ''
          }
        }
      }
      this.minETAToDate = moment(etaFromDate).format(DateTimePicker.DATE_FORMAT)
      this.minETAToTime =
        moment(etaFromDate).format(DateTimePicker.DATE_FORMAT) === moment(etaToDate).format(DateTimePicker.DATE_FORMAT)
          ? moment(etaFromDate).format(DateTimePicker.TIME_FORMAT)
          : ''
      if (moment(etaFromDate) > moment(etaToDate)) {
        if (this.etaFromOldValue === oldValue) {
          this.acceptAutoDeploymentJobModel.etaTo = this.acceptAutoDeploymentJobModel.etaFrom
          this.isWrongDateRange = moment(etaFromDate) > slaCutOffTime ? true : false
        } else if (this.etaToOldValue === oldValue) {
          this.isWrongDateRange = true
        }
      } else {
        // check customer is available within contractor ETA
        let validETAFromCount = 0
        let validETAToCount = 0
        const dateFormat = 'YYYY-MM-DD'
        const timeSlots = this.customerAvailability.timeSlot
        if (this.customerAvailability.timeSlot) {
          // check is ETAFrom Date Valid compared to customerAvailability
          for (const timeSlot of timeSlots) {
            if (etaFromDate.isSame(timeSlot.startTime)) {
              validETAFromCount = validETAFromCount + 1
            } else if (etaFromDate.isBetween(timeSlot.startTime, timeSlot.endTime)) {
              validETAFromCount = validETAFromCount + 1
            }
          }
          this.isWrongTimeRange = validETAFromCount >= 1 ? false : true
          if (!this.isWrongTimeRange) {
            // check is ETATo Date Valid compared to customerAvailability
            for (const timeSlot of timeSlots) {
              if (etaToDate.isSame(timeSlot.endTime) || etaToDate.isSame(timeSlot.startTime)) {
                validETAToCount = validETAToCount + 1
              } else if (etaToDate.isBetween(timeSlot.startTime, timeSlot.endTime)) {
                validETAToCount = validETAToCount + 1
              }
            }
            this.isWrongTimeRange = validETAToCount >= 1 ? false : true
          }
        } else {
          this.isWrongTimeRange = false
        }
      }
      this.etaFromOldValue = this.acceptAutoDeploymentJobModel.etaFrom
      this.etaToOldValue = this.acceptAutoDeploymentJobModel.etaTo
    }
  }

  private isAvailabilityWithinSLATime() {
    // check whether customer availability slots outside of SLA or not
    const emergencyCreatedDate: moment.Moment | null = this.contractorOfferedJobModel.firstUpdatedAt
      ? this.contractorOfferedJobModel.firstUpdatedAt
      : moment()

    let result = true
    const timeSlots = this.customerAvailability.timeSlot || []
    if (emergencyCreatedDate && timeSlots.length > 0) {
      if (!this.contractorOfferedJobModel.engineerSiteSLA) {
        result = false
        return result
      }

      const duration = moment.duration(
        moment(this.contractorOfferedJobModel.engineerSiteSLA).diff(emergencyCreatedDate)
      )
      const hours = duration.asHours()

      let emergencySLATimeslotCount = 0
      let availabilityCount = 0
      const emergencyCreatedAtHours = moment(emergencyCreatedDate).toDate().getHours() // emergency create at time(hours)
      const startTimeHours = emergencyCreatedAtHours // loop starts from time(hours)
      const endTimeHours = startTimeHours + Math.round(hours) // loop end to time(hours)
      for (let i = startTimeHours; i <= endTimeHours; i++) {
        // set start time and end time
        const slotStartTime = new Date(moment(emergencyCreatedDate).toDate().setHours(i, 0, 0))
        const slotEndTime = new Date(
          moment(emergencyCreatedDate)
            .toDate()
            .setHours(i + 1, 0, 0)
        )
        emergencySLATimeslotCount++ // update emergency SLA Timeslot Count
        // check timeslots available within SLA time
        const timeSlotIndex = timeSlots.findIndex(
          (t) =>
            new Date(moment(t.startTime).toDate()).toString() === slotStartTime.toString() &&
            new Date(moment(t.endTime).toDate()).toString() === slotEndTime.toString()
        )
        if (timeSlotIndex !== -1) {
          availabilityCount++
        }
      }
      result = emergencySLATimeslotCount > 0 ? availabilityCount === emergencySLATimeslotCount : false
    }
    return result
  }
}
</script>

<style scoped>
.popup-link {
  text-decoration: underline;
}
.job-action-time-expired {
  padding: 10px;
}
</style>
