<template>
  <div v-if="!createdJobId">
    <v-stepper v-model="stepIndex" non-linear class="elevation-0">
      <v-stepper-header>
        <v-stepper-step
          :step="1"
          :editable="stepIndex > 1"
          :complete="step1validated"
          class="si-job-step-1"
          @click.native.stop="switchToStep(1)"
        >
          Policy Details
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :step="2"
          :editable="step1validated && stepIndex > 1"
          :complete="step2validated"
          class="si-job-step-2"
          @click.native.stop="switchToStep(2)"
        >
          Select package
        </v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step
          :step="3"
          :editable="step1validated && step2validated && stepIndex > 1"
          :complete="wizardSubmitStatus === 'submitted'"
          class="si-job-step-3"
          @click.native.stop="switchToStep(3)"
        >
          Contact Preferences
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-content step="1">
        <v-form autocomplete="off">
          <v-container fluid pa-0 grid-list-lg>
            <v-layout wrap>
              <v-layout wrap>
                <v-flex xs12 pa-0>
                  <v-flex>
                    <PolicySelector
                      v-if="policies"
                      id="policySelector"
                      ref="policySelectorRef"
                      v-model="selectedPolicy"
                      v-validate="'required'"
                      style="padding-top: 10px"
                      type="number"
                      required
                      :policy-tree="policies"
                      :error-messages="validationMessage('Selected Policy')"
                      :data-vv-scope="step1formRef"
                      data-vv-name="Selected Policy"
                      :selected-policy="selectedPolicy"
                      class="mb-4 pb-0 pl-0"
                      @update:selectedPolicy="updatePolicySelection"
                    />
                    <v-select
                      v-else
                      v-model="claimModel.policyScheduleId"
                      v-validate="'required'"
                      :disabled="true"
                      type="number"
                      :placeholder="claimModel.policyScheme"
                      data-vv-name="Selected Policy"
                    />
                    <v-alert
                      v-if="showDisclaimer"
                      color="warning"
                      icon="priority_high"
                      class="pa-0 waring-maping"
                      dismissible
                    >
                      DISCLAIMER: {{ disclaimerMessage }}
                    </v-alert>
                    <v-alert
                      v-model="isAssumedCoverPolicy"
                      color="warning"
                      icon="priority_high"
                      outline
                      class="pa-0"
                      style="border: 0px !important; top: -15px !important"
                    >
                      This is an assumed cover policy, you should create a job even if you can't find the customer in
                      the validation database.
                    </v-alert>
                  </v-flex>
                </v-flex>
                <v-flex xs12 py-0>
                  <CustomerAddress
                    ref="addCustomerAddress"
                    :passed-client-title.sync="getClientTitle"
                    :passed-first-name.sync="getFirstName"
                    :passed-last-name.sync="getLastName"
                    :passed-policy-number.sync="getPolicyNumber"
                    :passed-address-line1.sync="getAddressLine1"
                    :passed-address-line2.sync="getAddressLine2"
                    :passed-address-line3.sync="getAddressLine3"
                    :passed-address-line4.sync="getAddressLine4"
                    :passed-post-code.sync="getPostCode"
                    :is-display-policy-number="true"
                    :is-s-i-job="true"
                  ></CustomerAddress>
                </v-flex>
                <v-flex xs6 py-0 pt-2>
                  <DateTimePicker
                    ref="dateTimePicker"
                    :date-time.sync="instructionDate"
                    :is-static-location="false"
                    place-holder-text="Instruction Received"
                    :is-current-time="true"
                    :is-validation-required="true"
                    :show-present-time-icon="true"
                  />
                </v-flex>
                <v-flex xs6 py-0 pt-2>
                  <v-text-field
                    v-model="claimModel.customerRef"
                    v-validate="'required'"
                    label="Client Reference"
                    required
                    class="required"
                    name="ClientReference"
                    data-vv-name="Client Reference"
                    :data-vv-scope="step1formRef"
                    :error-messages="validationMessage('Client Reference')"
                  ></v-text-field>
                </v-flex>

                <v-flex xs6 pr-2>
                  <v-select
                    v-model="selectedClaimManagementCompany"
                    v-validate="'required'"
                    label="Claim Management Company"
                    :items="claimManagementCompanies"
                    item-text="description"
                    return-object
                    required
                    name="ClaimManagementCompany"
                    class="required"
                    data-vv-name="Claim Management Company"
                    :data-vv-scope="step1formRef"
                    :error-messages="validationMessage('Claim Management Company')"
                    @change="updateClaimManagementCompany"
                  />
                </v-flex>
                <v-flex xs6 pl-2>
                  <v-autocomplete
                    v-model="selectedSubsidenceConsultant"
                    v-validate="'required'"
                    label="Subsidence Consultant"
                    :items="subsidenceConsultants"
                    item-text="description"
                    return-object
                    required
                    class="required"
                    data-vv-name="Subsidence Consultant"
                    :data-vv-scope="step1formRef"
                    :error-messages="validationMessage('Subsidence Consultant')"
                    @change="updateSubsidenceConsultant"
                  />
                </v-flex>
              </v-layout>
            </v-layout>
          </v-container>
        </v-form>
      </v-stepper-content>
      <v-stepper-content step="2">
        <v-form autocomplete="off">
          <v-layout wrap>
            <v-checkbox
              v-model="claimModel.isIVSJob"
              :label="`is an IVS job?`"
              color="primary"
              class="isIVSJob"
              @change="clearJobPackageSelection"
            ></v-checkbox>
          </v-layout>
          <PackageSelection
            v-if="selectedClaimManagementCompany && selectedClaimManagementCompany.id && !claimModel.isIVSJob"
            ref="packageSelection"
            v-model="selectedJobPackage"
            :claim-management-company-id="selectedClaimManagementCompany.id"
            class="package-selection"
          />
        </v-form>
        <v-snackbar
          v-if="!claimModel.isIVSJob"
          v-model="showSnackbar"
          top
          multi-line
          color="error"
          :timeout="snackbarTimeout"
        >
          <h4>{{ snackbarText }}</h4>
          <v-btn flat @click.native="showSnackbar = false">Close</v-btn>
        </v-snackbar>
      </v-stepper-content>
      <v-stepper-content step="3">
        <v-form autocomplete="off">
          <v-container>
            <v-layout wrap>
              <v-flex xs12>
                <ContactPreferenceDetails
                  ref="contactPreferenceDetails"
                  :customer-contact-preference-modal="customerContactPreferenceModal"
                  @updateContactPreference="updateContactPreference"
                ></ContactPreferenceDetails>
              </v-flex>
            </v-layout>
          </v-container>
        </v-form>
      </v-stepper-content>
    </v-stepper>
  </div>
  <div v-else>
    <v-card class="elevation-0 pa-0 upload-document">
      <v-card-text class="pa-0">
        <v-layout wrap>
          <v-flex xs12 px-1 mb-2>
            <UploadDocument
              ref="uploadDocument"
              :job-id="createdJobId"
              :document-types="uploadedDocumentTypes"
              :is-in-wizard-step="true"
              @documentUploadComplete="onDocumentUploadComplete"
            ></UploadDocument>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import DialogWizard from '@/common/DialogWizard'
import Shared from '@/common/shared'
import { isNullOrUndefined } from 'util'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import { ContactPreferenceEnum } from '@/common/enums'
import { JobType } from '@/models/types'
import Store from '@/store'
import eventBus from '@/common/bus'
import moment from 'moment'

// models
import AddSIJob from '@/models/requests/AddSIJob'
import UpdateCustomerContactPreferenceModal from '@/models/requests/UpdateCustomerContactPreferenceModal'
import SubsidenceConsultantModel from '@/models/SubsidenceConsultantModel'
import ClaimManagementCompanyModel from '@/models/ClaimManagementCompanyModel'
import { SelectedPolicy, InsurerBrandList } from '@/models/policy/PolicyLists'
import BrandPolicyModel from '@/models/policy/BrandPolicyModel'
import UploadedDocumentTypeModel from '@/models/UploadedDocumentTypeModel'
import JobPackageState from '@/models/siteInvestigation/JobPackageState'

// components
import PolicySelector from '@/components/PolicySelector.vue'
import CustomerAddress from '@/components/CustomerAddress.vue'
import DateTimePicker from '@/components/DateTimePicker.vue'
import PropertyCard from '@/components/PropertyCard.vue'
import ContactPreferenceDetails from '@/components/ContactPreferenceDetails.vue'
import UploadDocument from '@/components/UploadDocument.vue'
import PackageSelection from '@/components/PackageSelection.vue'

// controllers
import JobController from '@/api/jobController'
import SiteInvestigationController from '@/api/siteInvestigationController'
import DocumentController from '@/api/documentController'
import PolicyScheduleController from '@/api/policyScheduleController'

@Component({
  name: 'AddSIClaimWizard',
  components: {
    PolicySelector,
    CustomerAddress,
    DateTimePicker,
    PropertyCard,
    ContactPreferenceDetails,
    UploadDocument,
    PackageSelection,
  },
})
export default class AddSIClaimWizard extends DialogWizard {
  public stepIndex = 1
  private step1validated = false
  private step2validated = false
  private step3validated = false

  private wizardSubmitStatus: 'none' | 'submitting' | 'submitted' = 'none'
  private wizardLoadState: 'unloaded' | 'loading' | 'loaded' = 'unloaded'

  private pageIndex = 1

  private step1formRef = 'asicw_step1form'
  private step2formRef = 'asicw_step2form'
  private step3formRef = 'asicw_step3form'

  private claimModel: AddSIJob = new AddSIJob()

  private selectedPolicy: SelectedPolicy | null = {
    insurer: null,
    brand: null,
    policy: null,
  }
  private policyList: BrandPolicyModel[] = []
  private policies: InsurerBrandList[] | null = null
  private prevPolicyId: number | undefined = undefined

  private contactPreference = ContactPreferenceEnum

  private customerContactPreferenceModal: UpdateCustomerContactPreferenceModal =
    new UpdateCustomerContactPreferenceModal()
  private wizardVisiblityTimeoutHandlerId: number | null = null

  private jobType: JobType = 'SI'
  private instructionDate: moment.Moment | null = null

  private disclaimerMessage = ''
  private showDisclaimer = false

  private selectedClaimManagementCompany: ClaimManagementCompanyModel | null = null
  private selectedSubsidenceConsultant: SubsidenceConsultantModel | null = null

  private selectedJobPackage: JobPackageState | null = null

  private snackbarTimeout = 3000
  private snackbarText = ''
  private showSnackbar = false

  private isJobCreated = false
  private createdJobId = ''
  private uploadedDocumentTypes: UploadedDocumentTypeModel[] = []
  private searchRunning = false
  private isAssumedCoverPolicy = false

  private get getClientTitle(): string {
    return this.claimModel.clientTitle
  }

  private set getClientTitle(newValue: string) {
    this.claimModel.clientTitle = newValue
  }

  private get getFirstName(): string {
    return this.claimModel.clientForename
  }

  private set getFirstName(newValue: string) {
    this.claimModel.clientForename = newValue
  }

  private get getLastName(): string {
    return this.claimModel.clientLastName
  }

  private set getLastName(newValue: string) {
    this.claimModel.clientLastName = newValue
  }

  private get getPolicyNumber(): string {
    return this.claimModel.policyNumber
  }

  private set getPolicyNumber(newValue: string) {
    this.claimModel.policyNumber = newValue
  }

  private get getAddressLine1(): string {
    return this.claimModel.addressLine1
  }

  private set getAddressLine1(newValue: string) {
    this.claimModel.addressLine1 = newValue
  }

  private get getAddressLine2(): string {
    return this.claimModel.addressLine2
  }

  private set getAddressLine2(newValue: string) {
    this.claimModel.addressLine2 = newValue
  }

  private get getAddressLine3(): string {
    return this.claimModel.addressLine3
  }

  private set getAddressLine3(newValue: string) {
    this.claimModel.addressLine3 = newValue
  }

  private get getAddressLine4(): string {
    return this.claimModel.addressLine4
  }

  private set getAddressLine4(newValue: string) {
    this.claimModel.addressLine4 = newValue
  }

  private get getPostCode(): string {
    return this.claimModel.postCode
  }

  private set getPostCode(newValue: string) {
    this.claimModel.postCode = newValue
  }

  private get policySelected(): boolean {
    return !isNullOrUndefined(this.selectedPolicy) && !isNullOrUndefined(this.selectedPolicy.policy)
  }

  private get subsidenceConsultants(): SubsidenceConsultantModel[] {
    return storeGetters.getSubsidenceConsultants()
  }

  private get claimManagementCompanies(): ClaimManagementCompanyModel[] {
    return storeGetters.getClaimManagementCompanies()
  }

  // Retrieves all policies in the system
  public async getPolicies() {
    if (this.searchRunning) {
      return
    }
    this.searchRunning = true
    this.policies = await PolicyScheduleController.GetPolicySchedule(false, this.jobType)
    this.searchRunning = false
    this.policyList = []
    if (this.policies && this.policies.length > 0) {
      const policies = Array.from(this.policies).map((b) => b.brands && b.brands.map((p) => p.policies))
      policies.forEach((policy: any) => {
        if (policy) {
          if (policy.flatMap) {
            const mappedPolicies = policy.flatMap((p) => p)
            mappedPolicies.forEach((p) => {
              this.policyList.push(p)
            })
          } else {
            this.policyList.push(policy)
          }
        }
      })
    } else {
      this.policies = []
    }
    // set select policy option by default on SI type
    this.claimModel.policyScheduleId = -1
  }

  public clearForm() {
    // Trigger a reset of the form
    if (this.stepIndex === 1) {
      this.wizardVisibilityChanged(true)
    } else if (this.stepIndex === 2) {
      this.selectedJobPackage = new JobPackageState()
      const packageSelection = this.$refs.packageSelection as PackageSelection
      this.claimModel.isIVSJob = false
    }
  }

  public switchToStep(step: number) {
    // don't move wizard if submitting/submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    if (step > this.stepIndex) {
      this.validateStep(this.stepIndex)
        .then((result: [number, string, boolean]) => {
          const passed: boolean = result[2]
          switch (result[1]) {
            case this.step1formRef:
              this.step1validated = passed
              break
            case this.step2formRef:
              this.step2validated = passed
              break
            case this.step3formRef:
              this.step3validated = passed
              break
          }
          if (passed) {
            switch (step) {
              case 2:
                this.wizardPageChanged('Next')
                break
              case 3:
                this.wizardPageChanged('Submit')
                break
              default:
                this.wizardPageChanged('Next')
            }
            this.stepIndex = step
          } else {
            this.stepIndex = result[0]
          }
        })
        .catch((err: any) => {
          eventBus.$emit('validationErrorHandler')
        })
    } else {
      this.stepIndex = step
      this.wizardPageChanged('Next')
    }
  }

  public processCurrentPage(): void {
    this.validateStep(this.stepIndex)
      .then((result: [number, string, boolean]) => {
        const passed: boolean = result[2]
        switch (result[1]) {
          case this.step1formRef:
            this.step1validated = passed
            if (passed) {
              this.wizardPageChanged('Next')
              this.stepIndex = 2
            }
            break
          case this.step2formRef:
            this.step2validated = passed
            if (passed) {
              this.wizardPageChanged('Submit')
              this.stepIndex = 3
            }
            break
          case this.step3formRef:
            this.step3validated = passed
            if (passed) {
              if (!this.step2validated) {
                this.stepIndex = 2
                this.wizardPageChanged('Next')
              } else {
                this.saveClaim()
              }
            }
            break
        }
      })
      .catch((err: any) => {
        eventBus.$emit('validationErrorHandler')
      })
  }

  @Watch(DialogWizard.WizardVisiblePropName)
  protected async wizardVisibilityChanged(newValue: boolean): Promise<void> {
    if (newValue) {
      // get policies
      if (!this.policies || this.policies.length === 0) {
        await this.getPolicies()
      }

      this.resetUploadDocumentProperties()
      //  wizard shown, reset form
      this.claimModel = new AddSIJob()

      // reset policy selector
      const policySelectorRef = this.$refs.policySelectorRef as PolicySelector
      if (policySelectorRef !== undefined) {
        policySelectorRef.setDefaultValues()
      }

      // reset address
      const customerAddress = this.$refs.addCustomerAddress as CustomerAddress
      if (customerAddress !== undefined) {
        customerAddress.setDefaultValues()
      }

      // reset drop-down
      this.selectedClaimManagementCompany = null
      this.selectedSubsidenceConsultant = null
      await this.checkClaimManagementCompanies()
      await this.checkSubsidenceConsultants()

      // reset contact preference details
      const contactPreferenceDetails = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
      if (contactPreferenceDetails !== undefined) {
        contactPreferenceDetails.setDefaultValues()
      }
      this.customerContactPreferenceModal = new UpdateCustomerContactPreferenceModal()

      // reset Instruction Date
      this.setInstructionDate()
      const dateTimePicker = this.$refs.dateTimePicker as DateTimePicker
      if (dateTimePicker !== undefined) {
        dateTimePicker.$validator.errors.items = []
      }

      // reset package selection
      this.selectedJobPackage = null

      this.updateContentHeight(550)
      this.wizardPageChanged('Next')

      // update internal objects
      this.stepIndex = 1
      this.step1validated = false
      this.step2validated = false
      this.step3validated = false
      this.wizardSubmitStatus = 'none'

      this.selectedPolicy = null
      this.isAssumedCoverPolicy = false

      if (this.wizardVisiblityTimeoutHandlerId) {
        window.clearTimeout(this.wizardVisiblityTimeoutHandlerId)
      }
      this.wizardVisiblityTimeoutHandlerId = window.setTimeout(() => {
        this.$validator.errors.items = []
      }, 0)
    }
  }

  @Watch('selectedPolicy')
  protected policyChanged() {
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      const policy: BrandPolicyModel | undefined = this.policyList.find((p) => p.id === this.selectedPolicy!.policy!.id)
      if (this.prevPolicyId && this.selectedPolicy.policy.id !== this.prevPolicyId) {
        if (policy && policy.disclaimer && policy.disclaimer.trim() !== '') {
          this.disclaimerMessage = policy.disclaimer
          this.showDisclaimer = true
        }
        this.claimModel.policyNumber = ''
      }
      this.prevPolicyId = this.selectedPolicy.policy!.id
      this.isAssumedCoverPolicy = policy && policy.isStarred ? true : false
    } else {
      this.isAssumedCoverPolicy = false
    }
  }

  private async mounted() {
    await this.checkClaimManagementCompanies()
    await this.checkSubsidenceConsultants()
  }

  private saveClaim() {
    this.setContactPreferenceDetail()
    this.setInstructionDate(this.instructionDate)

    // ignore if currently submitting or submitted
    if (this.wizardSubmitStatus !== 'none') {
      return
    }
    this.wizardBackEndOperation(true)
    this.saveJobClaim()
  }

  private saveJobClaim() {
    if (this.selectedJobPackage) {
      const dehydratedModel = this.selectedJobPackage.dehydratedModel
      const packageSelection = this.$refs.packageSelection as PackageSelection
      if (packageSelection) {
        // set base price in product child link of monitoring product
        dehydratedModel.monitoringProducts.forEach((monitoringProduct) => {
          const selectedProduct = packageSelection.tempMonitoringProductItems.find(
            (e) => e.value.id === monitoringProduct.id
          )
          monitoringProduct.productChildLinks = selectedProduct
            ? selectedProduct.value.productChildLinks
            : monitoringProduct.productChildLinks
        })
      }
      this.claimModel.jobPackage = dehydratedModel
    }
    JobController.SaveSIClaim(this.claimModel)
      .then((res: string | null) => {
        this.wizardSubmitStatus = 'submitted'
        this.wizardBackEndOperation(false)
        // submitted ok, route to it
        if (!res) {
          eventBus.$emit('errorHandler', 'error submitting job (SaveSIClaim), please try again', true)
          this.wizardSubmitStatus = 'none'
          return
        }

        this.wizardVisibilityChanged(true)
        // get list of upload type
        this.getUploadedDocumentTypes(res)
      })
      .catch((err: any) => {
        this.wizardBackEndOperation(false)
        this.wizardSubmitStatus = 'none'
        eventBus.$emit('errorHandler', err, false)
      })
  }

  private setContactPreferenceDetail() {
    Object.keys(this.customerContactPreferenceModal).forEach(
      (key) => (this.claimModel[key] = this.customerContactPreferenceModal[key])
    )

    // reset email on sms/call selection
    if (
      this.claimModel.contactPreference === this.contactPreference[this.contactPreference.SMS] ||
      this.claimModel.contactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      this.claimModel.email = ''
    }
    // reset mobile phone on email selection
    if (this.claimModel.contactPreference === this.contactPreference[this.contactPreference.Email]) {
      this.claimModel.mobilePhone = ''
    }
    // reset reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.contactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.reasonForChoosingCallOption = ''
    }
    // nominee - reset email on sms/call selection
    if (
      this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.SMS] ||
      this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Call]
    ) {
      this.claimModel.nomineeEmail = ''
    }
    // nominee - reset mobile phone on email selection
    if (this.claimModel.nomineeContactPreference === this.contactPreference[this.contactPreference.Email]) {
      this.claimModel.nomineeMobilePhone = null
    }
    // nominee - reasonForChoosingCallOption on SMS/Email selection
    if (this.claimModel.nomineeContactPreference !== this.contactPreference[this.contactPreference.Call]) {
      this.claimModel.nomineeReasonForChoosingCallOption = ''
    }
  }

  private setInstructionDate(date: moment.Moment | null = null) {
    if (date !== null) {
      this.claimModel.instructionDate = moment.utc(date)
    } else {
      this.claimModel.instructionDate = null
      this.instructionDate = null
    }
  }

  private getUploadedDocumentTypes(newJobId: string) {
    this.uploadedDocumentTypes = []
    this.$emit('showUploadDocument', true)
    DocumentController.GetUploadedDocumentTypes(this.jobType).then((res: UploadedDocumentTypeModel[]) => {
      // error while getting jobtypes, route to it
      if (!res) {
        eventBus.$emit('errorHandler', 'error getting job types, please try to add from new job card', true)
        this.wizardSubmitStatus = 'none'
        this.sendWizardCompleteEvent()
        return
      }

      this.isJobCreated = true
      this.createdJobId = newJobId
      this.uploadedDocumentTypes = res
      this.$router
        .push({
          name: 'job',
          params: {
            jobId: newJobId,
          },
        })
        .catch(() => {})
    })
  }

  private resetUploadDocumentProperties() {
    this.$emit('showUploadDocument', false)
    this.createdJobId = ''
  }

  private onDocumentUploadComplete() {
    this.sendWizardCompleteEvent()
    eventBus.$emit('documentUploaded', true)
  }

  private updatePolicySelection(newSelected: SelectedPolicy) {
    this.selectedPolicy = newSelected
    if (this.selectedPolicy && this.selectedPolicy.policy) {
      this.claimModel.policyScheduleId = this.selectedPolicy.policy.id
      const policySelector: any = this.$el.querySelector('#policySelector')
      setTimeout(() => {
        policySelector.blur()
      }, 100)
    } else {
      this.selectedPolicy = null
    }
  }

  private async validateStep(step: number): Promise<[number, string, boolean]> {
    let scope = ''
    switch (step) {
      case 1:
        scope = this.step1formRef
        break
      case 2:
        scope = this.step2formRef
        break
      case 3:
        scope = this.step3formRef
        break
      default:
        return [step, scope, false]
    }

    const scopeValidation: boolean = await this.$validator.validateAll(scope)
    let componentValidation = true

    if (step === 1) {
      const dateTimePicker = this.$refs.dateTimePicker as DateTimePicker
      const dateTimePickerValidation = await dateTimePicker.$validator.validateAll()

      const addCustomerAddress = this.$refs.addCustomerAddress as CustomerAddress
      const addCustomerAddressValidation = await addCustomerAddress.$validator.validateAll()

      componentValidation = dateTimePickerValidation && addCustomerAddressValidation
    }

    if (step === 2) {
      if (!this.claimModel.isIVSJob) {
        if (this.selectedJobPackage) {
          const packageSelection = this.$refs.packageSelection as PackageSelection

          componentValidation = await packageSelection.validatePackageSelection()

          const configIssues = this.selectedJobPackage.configurationIssues

          if (configIssues) {
            componentValidation = false
            this.snackbarText = configIssues
            this.showSnackbar = true
          }
        } else {
          componentValidation = false
        }
      }
    }

    if (step === 3) {
      const contactPreferenceDetails = this.$refs.contactPreferenceDetails as ContactPreferenceDetails
      componentValidation = await contactPreferenceDetails.validateContactPreferenceStep()
    }

    // set focus to non validate field
    if (!scopeValidation || !componentValidation) {
      Shared.setValidationFocus(this.$el as HTMLElement)
    }

    return [step, scope, scopeValidation && componentValidation]
  }

  private validationMessage(label: string) {
    let message: string = this.$validator.errors.collect(label)[0]
    const errorMessage = label.split(/(\d+)/)
    if (label === 'Selected Policy') {
      return this.policySelected ? [] : ['A policy must be selected']
    }
    return message ? (message = 'The ' + errorMessage[0] + ' is required.') : message
  }

  private updateContactPreference(updateCustomerContactPreferenceModel: UpdateCustomerContactPreferenceModal) {
    this.customerContactPreferenceModal = updateCustomerContactPreferenceModel
  }

  private updateClaimManagementCompany(value: ClaimManagementCompanyModel) {
    this.selectedClaimManagementCompany = Object.assign(new ClaimManagementCompanyModel(), value)
    this.claimModel.claimManagementCompanyId = value.id
    this.claimModel.claimManagementCompany = value.description
  }

  private updateSubsidenceConsultant(value: SubsidenceConsultantModel) {
    this.selectedSubsidenceConsultant = Object.assign(new SubsidenceConsultantModel(), value)
    this.claimModel.subsidenceConsultantId = value.id
    this.claimModel.subsidenceConsultant = value.description
  }

  private clearJobPackageSelection() {
    this.selectedJobPackage = null
    this.step2validated = false
  }

  private async checkSubsidenceConsultants(): Promise<void> {
    try {
      if (!this.subsidenceConsultants.length) {
        const response = await SiteInvestigationController.GetSubsidenceConsultants()
        storeMutations.setSubsidenceConsultants(response)
      }
      if (this.subsidenceConsultants.length === 1) {
        this.updateSubsidenceConsultant(this.subsidenceConsultants[0])
      }
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading subsidence consultant list, please try again', true)
    }
  }

  private async checkClaimManagementCompanies(): Promise<void> {
    try {
      if (!this.claimManagementCompanies.length) {
        const response = await SiteInvestigationController.GetClaimManagementCompanies()
        storeMutations.setClaimManagementCompanies(response)
      }
      if (this.claimManagementCompanies.length === 1) {
        this.updateClaimManagementCompany(this.claimManagementCompanies[0])
      }
    } catch (error) {
      eventBus.$emit('errorHandler', 'Error loading claimManagement company list, please try again', true)
    }
  }
}
</script>

<style scoped>
.v-dialog__content .v-dialog >>> .v-card,
.application .theme--light.v-stepper,
.theme--light .v-stepper {
  background: #fafafa;
}
.v-dialog >>> .v-stepper {
  background: inherit;
}
.v-dialog .v-stepper >>> .v-stepper__header {
  box-shadow: none;
  text-transform: uppercase;
}
.v-dialog .v-stepper >>> .v-stepper__content {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 0;
}
.application .theme--light.v-stepper .v-stepper__step--editable:hover,
.theme--light .v-stepper .v-stepper__step--editable:hover {
  background: none;
}
.shadow-outer {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.enquiry-step3 {
  width: 240px;
}
.v-form > .container > .layout:only-child {
  margin: 0;
}
.v-input__slot {
  margin-bottom: 0;
}
.v-text-field__details {
  color: inherit;
  display: -ms-flexbox;
  display: flex;
  padding-top: 4px;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  font-size: 12px;
  min-height: 26px;
  overflow: hidden;
  position: relative;
  width: 100%;
  align-items: center;
}
.policy-logo {
  width: 118px;
  height: 100%;
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
    0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  position: relative;
}
.policy-logo img {
  padding: 0px 15px;
  width: 100%;
  margin: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.emergency-info-table {
  width: 100%;
  padding-left: 15px;
}
.emergency-info-table .shadow-outer.emergency-table {
  height: 100%;
}
</style>
