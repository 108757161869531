import SessionDetail from '@/models/auth/SessionDetail'
import CustomerPolicyRetrieveResponse from '@/models/policy/CustomPolicyRetrieveResponse'

export default class InitialWizardData {
  public clientId: string
  public scheduleId: string
  public selectedPolicy: CustomerPolicyRetrieveResponse
  public isCommercialPropertyPolicy: boolean | null
  public sessionDetail: SessionDetail
  public fnolCallSid: string
}
