export class AutoDeployment {
  public id = ''
  public emergencyId = ''
  public jobId = ''
  public status = ''
  public createdAt: Date = new Date()
  public trade = ''
  public postcode = ''
  public longitude: number | null = null
  public latitude: number | null = null
  public delays: Delay[] = []
  public contractors: ContractorDeployment[] = []
}

export class ContractorDeployment {
  public contractorId = ''
  public contractorName = ''
  public rank: number = 0
  public strategy = ''
  public appointmentRequest: ContractorAppointment | null
}

export class ContractorAppointment {
  public id = ''
  public status: AppointmentRequestStatus
  public createdAt: Date = new Date()
  public updatedAt: Date | null = null
  public updatedBy: string | null = null
  public updatedReason: string | null = null
  public requestExpiration: Date | null = null
  public notifications: AppointmentRequestNotification[] = []
}

export class AppointmentRequestNotification {
  public type = ''
  public contactInfo = ''
  public date: Date = new Date()
}

export enum AppointmentRequestStatus {
  NotOffered = 'NotOffered',
  Offered = 'Offered',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
  Unavailable = 'Unavailable',
  NoResponse = 'NoResponse',
}

export class Delay {
  public delayType: string
  public delayDate: Date
  public delayReason: string
}

export const DeploymentStatusMap = {
  [AppointmentRequestStatus.Accepted]: { color: 'green', text: 'Accepted' },
  [AppointmentRequestStatus.NoResponse]: { color: 'grey', text: 'No Response' },
  [AppointmentRequestStatus.NotOffered]: { color: 'grey', text: 'Not Offered' },
  [AppointmentRequestStatus.Offered]: { color: 'blue', text: 'Offered' },
  [AppointmentRequestStatus.Rejected]: { color: 'red', text: 'Rejected' },
  [AppointmentRequestStatus.Unavailable]: { color: 'red', text: 'Unavailable' },
}
