import { AxiosResponse } from 'axios'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import CommunicationPreferences from '@/models/contractor/CommunicationPreferences'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import ContractorJobDetailModel from '@/models/contractor/ContractorJobDetailModel'
import JobHeader from '@/models/JobHeader'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import Job from '@/models/Job'
import EmergencyQA from '@/models/EmergencyQA'
import EngineerRequestModel from '@/models/claim/EngineerRequestModel'
import Emergency from '@/models/Emergency'
import EngineerVisitDetail from '@/models/claim/EngineerVisitDetailModel'
import JobContractors from '@/models/contractor/JobContractorsModel'
import ContractorSignupDetailModel from '@/models/contractor/ContractorSignupDetailModel'
import ErrorResponseModel from '@/models/ErrorResponseModel'
import UploadedContractorDocumentDetail from '@/models/contractor/UploadedContractorDocumentDetail'
import HealthSafetyWellbeingEnvironment from '@/models/contractor/HealthSafetyWellbeingEnvironment'
import BankAccountDetail from '@/models/contractor/BankAccountDetailModel'
import Signature from '@/models/contractor/SignatureModel'
import ContractorSignupPasswordDetailsModel from '@/models/contractor/ContractorSignupPasswordDetailsModel'
import VisitHealthAndSafetyProcess from '@/models/claim/VisitHealthAndSafetyProcessModel'
import ContractorOfferedJobModel from '@/models/ContractorOfferedJobModel'
import ContractorAcceptAutoDeploymentJob from '@/models/contractor/ContractorAcceptAutoDeploymentJobModel'
import ContractorRejectAutoDeploymentJob from '@/models/contractor/ContractorRejectAutoDeploymentJobModel'
import AutoDeployRequest from '@/models/requests/AutoDeployRequestModel'
import BoilerBreakdownQA from '@/models/claim/BoilerBreakdownQA'
import MakeContractorProxyRequest from '@/models/MakeContractorProxyRequest'
import ContractorProxyModel from '@/models/contractor/ContractorProxyModel'
import { PostcodeRegion } from '@/models/contractor/RegionsConfiguration'
import AccessNoteModel from '@/models/claim/AccessNoteModel'
import HealthAndSafetyQAModel from '@/models/claim/HealthAndSafetyQAModel'
import { AppointmentDetail } from './models/deployment/contractor/appointment-detail'

export default class ContractorController {
  public static async SaveContractor(contractor: ContractorModel): Promise<ContractorModel | string> {
    const contractorObj: ContractorModel = { ...contractor }
    delete contractorObj.contractorAvailability
    delete contractorObj.coverage
    const res = await CallCentreApi.post<ContractorModel>('Contractor/SaveContractor', contractorObj)
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async SaveContractorAvailability(contractor: ContractorModel): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post('Contractor/SaveContractorAvailability', contractor)
    return res.status >= 202 && res.status < 300
  }

  public static async SaveContractorCoverage(contractor: ContractorModel): Promise<ContractorModel> {
    const res: AxiosResponse<ContractorModel> = await CallCentreApi.post<ContractorModel>(
      'Contractor/SaveContractorCoverage',
      contractor
    )
    return res.data
  }

  public static async GetContractorDetails(contractorId: string): Promise<ContractorModel | null> {
    const res: AxiosResponse<ContractorModel> = await CallCentreApi.get<ContractorModel>(
      'Contractor/GetContractorDetails?id=' + encodeURIComponent(contractorId)
    )
    return res.data
  }

  // TODO: remove this when the map memory issue is sorted
  public static async GetJobContractorDetails(contractorId: string): Promise<ContractorModel | null> {
    const res: AxiosResponse<ContractorModel> = await CallCentreApi.get<ContractorModel>(
      'Contractor/GetJobContractorDetails?id=' + encodeURIComponent(contractorId)
    )
    return res.data
  }

  public static async GetAllContractors(): Promise<ContractorModel[]> {
    const res: AxiosResponse<ContractorModel[]> = await CallCentreApi.get<ContractorModel[]>(
      'Contractor/GetAllContractors'
    )
    return res.data ? res.data : []
  }

  public static async GetContractorBySimilarName(searchText: string) {
    const res: AxiosResponse<ContractorModel[]> = await CallCentreApi.get<ContractorModel[]>(
      'Contractor/GetContractorBySimilarName?searchText=' + searchText
    )
    return res.data ? res.data : []
  }

  public static async GetJobContractors(
    tradeId: number,
    latitude: number | null,
    longitude: number | null,
    postcode: string,
    jobId: string,
    emergencyId: string
  ): Promise<JobContractors | string> {
    const res: AxiosResponse<JobContractors | string> = await CallCentreApi.get<JobContractors>(
      'Contractor/GetJobContractors?tradeId=' +
        encodeURIComponent(tradeId.toString()) +
        '&latitude=' +
        encodeURIComponent(latitude ? latitude.toString() : '') +
        '&longitude=' +
        encodeURIComponent(longitude ? longitude.toString() : '') +
        '&postcode=' +
        encodeURIComponent(postcode.toString()) +
        '&jobId=' +
        encodeURIComponent(jobId.toString()) +
        '&emergencyId=' +
        encodeURIComponent(emergencyId.toString())
    )
    return res.status >= 200 && res.status < 300 ? res.data : res.statusText
  }

  public static async AssignRatePackageToContractor(
    contractorRatePackage: ContractorRatePackageModel,
    contractorId: string
  ): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Contractor/AssignRatePackageToContractor?contractorId=' + encodeURIComponent(contractorId),
      contractorRatePackage
    )
    const result = res.data as string
    return result ? result : ''
  }

  public static async GetAllContractorJobs(contractorId: string): Promise<ContractorAppointedModel[]> {
    const res: AxiosResponse<ContractorAppointedModel[]> = await CallCentreApi.get<ContractorAppointedModel[]>(
      'Contractor/GetAllContractorJobs?contractorId=' + encodeURIComponent(contractorId)
    )
    return res.data ? res.data : []
  }

  public static async GetContractorJobDetailWithEmergencyDefinition(
    jobId: string,
    contractorAppointedDetailId: string
  ): Promise<ContractorJobDetailModel | null> {
    const res: AxiosResponse = await CallCentreApi.get(
      'Contractor/GetContractorJobDetailWithEmergencyDefinition?jobId=' +
        encodeURIComponent(jobId) +
        '&contractorAppointedDetailId=' +
        encodeURIComponent(contractorAppointedDetailId)
    )
    if (res.data) {
      if (res.data && res.data.details) {
        const resData = res.data.details as any[]
        const result: ContractorJobDetailModel = new ContractorJobDetailModel()
        await this.UnpackJobDetails(resData, result)
        if (res.data.emergencyPolicyDefinition) {
          result.emergencyPolicyDefinition = res.data.emergencyPolicyDefinition
        }
        if (res.data.vulnerabilityWarning) {
          result.vulnerabilityWarning = res.data.vulnerabilityWarning
        }
        return result
      } else {
        return null
      }
    } else {
      return null
    }
  }

  private static async UnpackJobDetails(resData: any[], result: ContractorJobDetailModel) {
    for (const item of resData) {
      switch (item.type) {
        case 'JobDetail':
          result.jobDetail = Object.assign(new Job(), item)
          break
        case 'EmergencyQA':
          result.emergenciesQA.push(Object.assign(new EmergencyQA(), item))
          break
        case 'PictureUpload':
          result.picturesUpload.push(item)
          break
        case 'EngineerRequest':
          result.engineerRequests.push(Object.assign(new EngineerRequestModel(), item))
          break
        case 'AccessNote':
          result.accessNotes = Object.assign(new AccessNoteModel(), item)
          break
        case 'HealthAndSafetyQA':
          result.healthAndSafetyQA = Object.assign(new HealthAndSafetyQAModel(), item)
          break
        case 'EmergencyDetail':
          result.emergencyDetail = Object.assign(new Emergency(), item)
          break
        case 'EngineerVisitDetail':
          result.engineerVisitDetails = Object.assign(new EngineerVisitDetail(), item)
          break
        case 'BoilerBreakdownQA':
          result.boilerBreakdownQA = Object.assign(new BoilerBreakdownQA(), item)
          break
        case 'VisitHealthAndSafetyProcess':
          result.healthSafetyQAs.push(Object.assign(new VisitHealthAndSafetyProcess(), item))
          break
        case 'ContractorAppointedDetail':
          result.contractorAppointedDetail = Object.assign(new ContractorAppointedModel(), item)
          break
        default:
        // commented below line to restrict error on unknown case.
        // throw new Error("unknown job document type: " + item.type);
      }
    }
  }

  public static async SearchContractorJobHeader(contractorId: string, searchKeyword: string): Promise<JobHeader[]> {
    const res: AxiosResponse<JobHeader[]> = await CallCentreApi.get<JobHeader[]>(
      'contractor/SearchContractorJobHeader?contractorId=' + contractorId + '&keyword=' + searchKeyword.toUpperCase()
    )
    return res.data ? res.data : []
  }

  public static async GetNearByContractors(
    tradeId: number,
    latitude: number | null,
    longitude: number | null,
    distance: string | null
  ): Promise<ContractorModel[]> {
    const res: AxiosResponse<ContractorModel[]> = await CallCentreApi.get<ContractorModel[]>(
      'Contractor/GetNearByContractors?tradeId=' +
        encodeURIComponent(tradeId.toString()) +
        '&latitude=' +
        encodeURIComponent(latitude ? latitude.toString() : '') +
        '&longitude=' +
        encodeURIComponent(longitude ? longitude.toString() : '') +
        '&distance=' +
        encodeURIComponent(distance ? distance.toString() : '0')
    )
    return res.data ? res.data : []
  }

  public static async plotContractorOpenJobs(contractorId: string): Promise<ContractorAppointedModel[] | null> {
    const res: AxiosResponse<ContractorAppointedModel[]> = await CallCentreApi.get(
      'Contractor/GetAllContractorOpenJobs?contractorId=' + contractorId
    )

    return res.data ? res.data : []
  }

  public static async GetTokenToUploadContractorDocument(contractorId: string, documentName: string): Promise<string> {
    const res: AxiosResponse = await CallCentreApi.get<string>(
      'Contractor/GetTokenToUploadContractorDocument?contractorId=' + contractorId + '&documentName=' + documentName
    )
    return res.data
  }

  public static async GetContractorDocumentMetadata(
    contractorId: string
  ): Promise<UploadedContractorDocumentDetail[] | null> {
    const res: AxiosResponse<UploadedContractorDocumentDetail[] | null> = await CallCentreApi.get(
      'Contractor/GetContractorDocumentMetadata?contractorId=' + contractorId
    )
    return res.data ? res.data : []
  }

  public static async SaveTradeMembershipDetails(model: ContractorModel): Promise<boolean> {
    const res: AxiosResponse<ErrorResponseModel> = await CallCentreApi.post(
      'Contractor/SaveTradeMembershipDetails',
      model
    )
    return res.status >= 202 && res.status < 300
  }

  public static async DeleteUploadedFile(contractorId: string, fileUrl: string): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Contractor/DeleteUploadedFile?contractorId=' + contractorId + '&fileURL=' + fileUrl
    )
    return res.status >= 200 && res.status < 300
  }

  public static async SaveHealthSafetyWellbeingEnvironment(
    contractorId: string,
    model: HealthSafetyWellbeingEnvironment
  ): Promise<boolean> {
    const res: AxiosResponse<ErrorResponseModel> = await CallCentreApi.post(
      'Contractor/SaveHealthSafetyWellbeingEnvironment?contractorId=' + contractorId,
      model
    )
    return res.status >= 202 && res.status < 300
  }

  public static async SaveContractorBankDetail(bankDetail: BankAccountDetail, contractorId: string): Promise<boolean> {
    const res = await CallCentreApi.post('Contractor/SaveContractorBankDetail?contractorId=' + contractorId, bankDetail)
    return res.status >= 202 && res.status < 300
  }

  public static async SaveSignature(signature: Signature, contractorId: string): Promise<boolean> {
    const res = await CallCentreApi.post('Contractor/SaveSignature?contractorId=' + contractorId, signature)
    return res.status >= 202 && res.status < 300
  }

  public static async SaveContractorCompanyDetail(contractor: ContractorModel): Promise<boolean> {
    const contractorObj: ContractorModel = { ...contractor }
    delete contractorObj.contractorAvailability
    delete contractorObj.coverage
    const res = await CallCentreApi.post<ContractorModel>('Contractor/SaveContractorCompanyDetail', contractorObj)
    return res.status >= 202 && res.status < 300
  }

  public static async ContractorSignup(model: ContractorSignupDetailModel): Promise<ErrorResponseModel | null> {
    const res: AxiosResponse<ErrorResponseModel> = await CallCentreApi.post('Contractor/ContractorSignup', model)
    return res.data ? res.data : null
  }

  public static async ContractorSignupPassword(
    contractorSignupPasswordDetailsModel: ContractorSignupPasswordDetailsModel
  ): Promise<ErrorResponseModel | null> {
    const res: AxiosResponse = await CallCentreApi.post(
      'Contractor/ContractorSignupPassword',
      contractorSignupPasswordDetailsModel
    )
    return res.data ? res.data : null
  }

  public static async SubmitContractorToReview(contractorId: string): Promise<boolean> {
    const res = await CallCentreApi.post('Contractor/SubmitContractorToReview?contractorId=' + contractorId)
    return res.status >= 202 && res.status < 300
  }

  public static async GetContractorProfileDetail(contractorId: string): Promise<ContractorModel | null> {
    const res: AxiosResponse<ContractorModel> = await CallCentreApi.get<ContractorModel>(
      'Contractor/GetContractorProfileDetail?contractorId=' + contractorId
    )
    if (res.status >= 200 && res.status < 300) {
      const result = res.data as ContractorModel
      if (!result) {
        return null
      }
      return result
    }
    return null
  }

  public static async GetCommunicationPreferences(contractorId: string) {
    const { data } = await CallCentreApi.get<CommunicationPreferences>(
      `contractors/${contractorId}/communication-preferences`
    )
    return data || new CommunicationPreferences()
  }

  public static async SaveCommunicationPreferences(contractorId: string, prefs: CommunicationPreferences) {
    const { status } = await CallCentreApi.patch(`contractors/${contractorId}/communication-preferences`, prefs)
    return status >= 200 && status < 300
  }

  public static async GetSubContractors(contractorIds: string[]): Promise<ContractorModel[]> {
    const res: AxiosResponse<ContractorModel[]> = await CallCentreApi.post<ContractorModel[]>(
      'Contractor/GetSubContractors',
      contractorIds
    )
    return res.data ? res.data : []
  }

  public static async GetContractorAppointedDetail(
    jobId: string,
    contractorAppointedId: string
  ): Promise<ContractorOfferedJobModel | null | string> {
    const res: AxiosResponse<ContractorOfferedJobModel> = await CallCentreApi.get<ContractorOfferedJobModel>(
      'Contractor/GetContractorAppointedDetail?jobId=' + jobId + '&contractorAppointedId=' + contractorAppointedId
    )
    return res.status >= 200 && res.status < 300 ? res.data : res.status === 400 ? '' : null
  }
  public static async ContractorRejectAutoDeploymentJob(
    rejectAutoDeploymentJobModel: ContractorRejectAutoDeploymentJob
  ): Promise<ContractorRejectAutoDeploymentJob | null> {
    const res: AxiosResponse<ContractorRejectAutoDeploymentJob> =
      await CallCentreApi.post<ContractorRejectAutoDeploymentJob>(
        'Contractor/ContractorRejectAutoDeploymentJob',
        rejectAutoDeploymentJobModel
      )
    return res.data ? res.data : null
  }
  public static async ContractorAcceptAutoDeploymentJob(
    acceptAutoDeploymentJobModel: ContractorAcceptAutoDeploymentJob
  ): Promise<ContractorAcceptAutoDeploymentJob | null> {
    const res: AxiosResponse<ContractorAcceptAutoDeploymentJob> =
      await CallCentreApi.post<ContractorAcceptAutoDeploymentJob>(
        'Contractor/ContractorAcceptAutoDeploymentJob',
        acceptAutoDeploymentJobModel
      )
    return res.data ? res.data : null
  }
  public static async AutoDeployRequest(autoDeployEngineerRequest: AutoDeployRequest): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.post<AutoDeployRequest>(
      'Contractor/AutoDeploymentRequest',
      autoDeployEngineerRequest
    )
    return res.status >= 200 && res.status < 300
  }
  public static async MakeContractorProxyCall(req: MakeContractorProxyRequest): Promise<ContractorProxyModel | string> {
    const res: AxiosResponse = await CallCentreApi.post<MakeContractorProxyRequest>(
      'Contractor/MakeContractorProxyCall',
      req
    )
    if (res.status === 200) {
      return res.data
    } else {
      // error state, ensure return
      // calling function should handle erroneous responses
      return res.statusText
    }
  }

  public static async CheckAnyMaterialRequestAwaitingPrice(
    jobId: string,
    previousEngineerVisitId: string
  ): Promise<boolean> {
    const res: AxiosResponse = await CallCentreApi.get<boolean>(
      'Contractor/CheckAnyMaterialRequestAwaitingPrice?jobId=' +
        jobId +
        '&previousEngineerVisitId=' +
        previousEngineerVisitId
    )
    return res.data ? res.data : false
  }

  public static async GetRegionConfiguration() {
    const res: AxiosResponse<PostcodeRegion[]> = await CallCentreApi.get<PostcodeRegion[]>(
      'Contractor/GetRegionConfiguration'
    )
    return res.data ? res.data : []
  }

  public static async UpdateRegionConfiguration(regionId: string, outcode: string) {
    const res: AxiosResponse<PostcodeRegion[]> = await CallCentreApi.patch<PostcodeRegion[]>(
      `Contractor/UpdateRegion?regionId=${regionId}&outcode=${outcode}`
    )
    return res.data ? res.data : []
  }

  public static async GetContactorJobOfferDetail(
    jobId: string,
    appointmentId: string
  ): Promise<AppointmentDetail | null> {
    const res: AxiosResponse<AppointmentDetail> = await CallCentreApi.get<AppointmentDetail>(
      `contractors/${jobId}/appointments/${appointmentId}`
    )
    // Convert to JS date object
    if (res.data) {
      res.data.customerAvailability.timeSlots.forEach((x) => {
        x.startTime = new Date(x.startTime)
        x.endTime = new Date(x.endTime)
      })
    }
    return res.data || null
  }
}
