import { AutoDeployment } from '@/models/autoDeployment/auto-deployment'
import CallCentreApi from '@/api/CallCentreApiAxiosPlugin'
import { AxiosResponse } from 'axios'
import { AcceptJobOfferRequest } from './models/deployment/accept-job-offer-request'
import { JobOfferReviewResponse } from './models/deployment/job-offer-review-response'
import { RejectJobOfferRequest } from './models/deployment/reject-job-offer-request'

export default class DeploymentController {
  public static async GetAutoDeploymentDetail(jobId: string, deploymentId: string): Promise<AutoDeployment> {
    const res: AxiosResponse = await CallCentreApi.get<AutoDeployment>(`deployments/${deploymentId}/${jobId}`)
    return res.data || null
  }

  public static async AcceptJobOffer(request: AcceptJobOfferRequest): Promise<JobOfferReviewResponse | null> {
    const res: AxiosResponse = await CallCentreApi.put<JobOfferReviewResponse>(`deployments/accept`, request)
    return res.data || null
  }

  public static async RejectJobOffer(request: RejectJobOfferRequest): Promise<JobOfferReviewResponse> {
    const res: AxiosResponse = await CallCentreApi.put<JobOfferReviewResponse>(`deployments/reject`, request)
    return res.data || null
  }
}
