import Axios, { AxiosResponse, AxiosInstance, InternalAxiosRequestConfig } from 'axios'
import Store from '@/store'
import moment from 'moment'
import type { MomentInput } from 'moment'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import ComplaintTrackerModel from '@/models/claim/ComplaintTrackerModel'
import JobDashboardModel from '@/models/claim/JobDashboardModel'
import EngineerModel from '@/models/contractor/EngineerModel'
import Job from '@/models/Job'
import InsurerModel from '@/models/policy/InsurerModel'
import PolicyModel from '@/models/policy/PolicyModel'
import EmergencyQuestionModel from '@/models/policy/EmergencyQuestionModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import ContractorRatePackageModel from '@/models/contractor/ContractorRatePackageModel'
import UserModel from '@/models/user/UserModel'
import ClientTemplateModel from '@/models/client/ClientTemplateModel'
import ClientTemplateReleaseModel from '@/models/client/ClientTemplateReleaseModel'
import ClientTemplateDeploymentModel from '@/models/client/ClientTemplateDeploymentModel'
import PublishClientTemplateRequestStatusModel from '@/models/client/PublishClientTemplateRequestStatusModel'
import UpdateClientTemplateRequestStatusModel from '@/models/client/UpdateClientTemplateRequestStatusModel'
import ErrorResponseModel from '@/models/ErrorResponseModel'
import eventBus from '@/common/bus'
import PreviousJobModel from '@/models/policyHolder/PreviousJobModel'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'

interface IObject {
  new ()
}

interface Assignment {
  method: string
  controllerAction: string
  class: IObject
}

const assignments: Assignment[] = [
  {
    method: 'post',
    controllerAction: 'Contractor/ContractorSignup',
    class: ErrorResponseModel,
  },
  {
    method: 'post',
    controllerAction: 'Contractor/SaveContractor',
    class: ContractorModel,
  },
  {
    method: 'post',
    controllerAction: 'Contractor/SaveContractorCoverage',
    class: ContractorModel,
  },
  {
    method: 'get',
    controllerAction: 'Contractor/GetContractorDetails',
    class: ContractorModel,
  },
  {
    method: 'get',
    controllerAction: 'Contractor/GetJobContractors',
    class: ContractorModel,
  },
  {
    method: 'get',
    controllerAction: 'Contractor/GetAllContractors',
    class: ContractorModel,
  },
  {
    method: 'get',
    controllerAction: 'Contractor/GetAllContractorJobs',
    class: ContractorAppointedModel,
  },
  {
    method: 'get',
    controllerAction: 'Contractor/GetNearByContractors',
    class: ContractorModel,
  },
  {
    method: 'get',
    controllerAction: 'Dashboard/GetComplaintTrackingDetail',
    class: ComplaintTrackerModel,
  },
  {
    method: 'get',
    controllerAction: 'Dashboard/GetJobDashboardData',
    class: JobDashboardModel,
  },
  {
    method: 'get',
    controllerAction: 'Dashboard/GetJobDetails',
    class: JobDashboardModel,
  },
  {
    method: 'get',
    controllerAction: 'Engineer/GetEngineerDetails',
    class: EngineerModel,
  },
  {
    method: 'get',
    controllerAction: 'Engineer/GetContractorEngineers',
    class: EngineerModel,
  },
  { method: 'post', controllerAction: 'jobs', class: Job },
  {
    method: 'get',
    controllerAction: 'Job/GetBasicDetailOfPolicyHolderJobs',
    class: PreviousJobModel,
  },
  { method: 'post', controllerAction: 'Job/AddPolicyEnquiry', class: Job },
  {
    method: 'post',
    controllerAction: 'Job/AddHelplinePolicyRequest',
    class: Job,
  },
  {
    method: 'get',
    controllerAction: 'PolicyHolder/GetTrades',
    class: TradeModel,
  },
  {
    method: 'get',
    controllerAction: 'Rate/GetAllContractorRatePackages',
    class: ContractorRatePackageModel,
  },
  { method: 'get', controllerAction: 'User/GetUserList', class: UserModel },
  { method: 'get', controllerAction: 'User/GetUserById', class: UserModel },
  {
    method: 'get',
    controllerAction: 'Insurer/GetInsurerList',
    class: InsurerModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetClientTemplateByInsurer',
    class: ClientTemplateModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetClientTemplate',
    class: ClientTemplateModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetBrandSpecificClientTemplates',
    class: ClientTemplateModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetClientTemplateReleaseHistory',
    class: ClientTemplateReleaseModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetClientTemplateDeployments',
    class: ClientTemplateDeploymentModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetPublishClientTemplateRequestStatus',
    class: PublishClientTemplateRequestStatusModel,
  },
  {
    method: 'get',
    controllerAction: 'Insurer/GetUpdateClientTemplateRequestStatus',
    class: UpdateClientTemplateRequestStatusModel,
  },
  {
    method: 'post',
    controllerAction: 'Insurer/SaveInsurer',
    class: InsurerModel,
  },
  {
    method: 'get',
    controllerAction: 'PolicySchedule/GetPoliciesByInsurerId',
    class: PolicyModel,
  },
  {
    method: 'get',
    controllerAction: 'PolicySchedule/GetPolicyById',
    class: PolicyModel,
  },
  {
    method: 'get',
    controllerAction: 'Question/GetPolicyLevelQuestions',
    class: EmergencyQuestionModel,
  },
  {
    method: 'get',
    controllerAction: 'Question/GetEmergencyLevelQuestions',
    class: EmergencyQuestionModel,
  },
  {
    method: 'get',
    controllerAction: 'Question/GetEmergencyDetailLevelQuestions',
    class: EmergencyQuestionModel,
  },
  {
    method: 'post',
    controllerAction: 'Question/SaveQuestion',
    class: EmergencyQuestionModel,
  },
].map((assignment) => {
  assignment.method = assignment.method.toLowerCase()
  assignment.controllerAction = assignment.controllerAction.toLowerCase()
  return assignment
})

const momentDateFields = [
  'dateOfResolution',
  'initialResponseLetterDate',
  'initialResponseLetterSent',
  'fourWeekLetterDate',
  'fourWeekLetterSent',
  'eightWeekLetterDate',
  'eightWeekLetterSent',
  'summaryResolutionCommunicationDate',
  'summaryResolutionCommunicationSent',
  'finalResponseLetterDate',
  'finalResponseLetterSent',
  'loggedAt',
  'etaFrom',
  'etaTo',
  'arrivalTime',
  'timeOnSite',
  'jobStartedAt',
  'createdAt',
  'createdDate',
  'createdDateUTC',
  'processedAt',
  'requestProcessedAt',
  'firstNoticed',
  'firstNoticedUTC',
  'firstNoticedAt',
  'firstNoticedAtUtc',
  'authorizationDate',
  'transactionDate',
  'callDateTime',
  'outsideFromDate',
  'outsideToDate',
]

function momentReviver(key: string, value: MomentInput | undefined) {
  if (momentDateFields.includes(key)) {
    const m = moment(value, moment.ISO_8601)
    return moment.isMoment(m) ? m : null
  }

  return value
}

export function handleError(userMessage: string, error: Error | null = null) {
  Store.Instance.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar(userMessage))
  console.error(error ? error.message : userMessage)
}

export function getBaseUrl(): string {
  return Store.Instance.state.Environment.CallCentreApiBaseUrl + '/api/'
}

function generateCallCentreApiAxiosInstance(): AxiosInstance {
  const axios = Axios.create({
    transformResponse: (data: unknown) => {
      if (typeof data !== 'string') {
        return data
      }

      try {
        return JSON.parse(data, momentReviver)
      } catch {
        //
      }

      try {
        return JSON.parse(data)
      } catch {
        //
      }

      return data
    },
    validateStatus: (status: number) => (status >= 200 && status < 300) || [404, 400, 409].includes(status),
  })

  axios.interceptors.request.use((config: InternalAxiosRequestConfig<unknown>) => {
    config.headers = config.headers || {}
    if (!config.headers.Authorization) {
      config.headers.Authorization = 'bearer ' + Store.Instance.state.SessionDetail.accessToken
    }
    config.baseURL = getBaseUrl()
    return config
  })

  axios.interceptors.response.use(
    (res: AxiosResponse<unknown>) => {
      if (res.config.method && res.config.url) {
        const url = res.config.url.toLowerCase()
        const method = res.config.method.toLowerCase()
        const match = assignments.find((a) => a.method === method && url.startsWith(a.controllerAction))
        if (match) {
          if (Array.isArray(res.data)) {
            res.data = res.data.map((data) => {
              return Object.assign(new match.class(), data)
            })
          } else {
            res.data = Object.assign(new match.class(), res.data)
          }
        }
      }
      return res
    },
    (error: unknown) => {
      // eslint:disable-next-line:no-console
      console.log('interceptor REST response error:')
      eventBus.$emit('errorHandler', error, false)
      // eslint:disable-next-line:no-console
      console.log(error)

      return Promise.reject(error)
    }
  )

  // return instance
  return axios
}

const axiosInstance = generateCallCentreApiAxiosInstance()
export default axiosInstance
