<template>
  <div>
    <h4 class="mb-1">Customer Availability</h4>
    <div v-if="hasDayAnyTimeSlots(timeSlotDayKey.today)">
      <h4>Today</h4>
      <v-chip
        v-for="(day, index) in retrieveTimeSlotsForDay(timeSlotDayKey.today)"
        :key="`${day.startTime}-${index}`"
        flat
        outline
        class="white--text"
        color="primary"
        dark
        small
        >{{ day.startTime }} - {{ day.endTime }}</v-chip
      >
    </div>

    <div v-if="hasDayAnyTimeSlots(timeSlotDayKey.tomorrow)">
      <h4>Tommorrow</h4>
      <v-chip
        v-for="(day, index) in retrieveTimeSlotsForDay(timeSlotDayKey.tomorrow)"
        :key="`${day.startTime}-${index}`"
        flat
        outline
        class="white--text"
        color="primary"
        dark
        small
        >{{ day.startTime }} - {{ day.endTime }}</v-chip
      >
    </div>

    <div v-if="hasDayAnyTimeSlots(timeSlotDayKey.thirdDay)">
      <h4>Third Day</h4>
      <v-chip
        v-for="(day, index) in retrieveTimeSlotsForDay(timeSlotDayKey.thirdDay)"
        :key="`${day.startTime}-${index}`"
        flat
        outline
        class="white--text"
        color="primary"
        dark
        small
        >{{ day.startTime }} - {{ day.endTime }}</v-chip
      >
    </div>
  </div>
</template>

<script lang="ts">
import Component from 'vue-class-component'
import Vue from 'vue'
import { CustomerAvailability } from '@/api/models/deployment/contractor/customer-availability-response'
import { Prop } from 'vue-property-decorator'
import { TimeSlot } from '@/api/models/deployment/contractor/time-slot-response'

@Component
export default class CustomerAvailabilityDisplay extends Vue {
  @Prop() private availability: CustomerAvailability

  public timeSlotDayKey = {
    today: 1,
    tomorrow: 2,
    thirdDay: 3,
  }

  public get timeSlotsGroupedByDay(): { [key: string]: TimeSlot[] } {
    return this.availability.timeSlots.reduce(
      (acc, timeslot) => (
        (acc[timeslot.availabilityOrder] = acc[timeslot.availabilityOrder] || []).push(timeslot), acc
      ),
      {}
    )
  }

  // This is for when we have times such as 3-4, 4-5, 5-6, 6-7
  // We want to group these so that we can display 3-7 to the user, instead of showing
  // every individual timeslot
  public groupConsecutiveSlots(slots: TimeSlot[]): TimeSlot[][] {
    if (!slots?.length) {
      return []
    }
    const sortedSlots = slots.sort((a, b) => a.startTime.getTime() - b.startTime.getTime())
    const groups: TimeSlot[][] = []
    let currentGroup: TimeSlot[] = []

    for (const slot of sortedSlots) {
      if (
        !currentGroup.length ||
        slot.startTime.getTime() === currentGroup[currentGroup.length - 1].endTime.getTime()
      ) {
        currentGroup.push(slot)
      } else {
        groups.push(currentGroup)
        currentGroup = [slot]
      }
    }

    if (currentGroup.length) {
      groups.push(currentGroup)
    }

    return groups
  }

  public retrieveTimeSlotsForDay(dayKey: number) {
    const slots = this.groupConsecutiveSlots(this.timeSlotsGroupedByDay[dayKey])
    return slots.map((slotArray) => {
      const firstSlot = slotArray[0]
      const lastSlot = slotArray[slotArray.length - 1]
      return {
        startTime: firstSlot.startTime.getHours(),
        endTime: lastSlot.endTime.getHours(),
      }
    })
  }

  public hasDayAnyTimeSlots(dayKey: number) {
    return this.availability.timeSlots.some((x) => x.availabilityOrder === dayKey)
  }
}
</script>
