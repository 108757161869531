<script lang="ts">
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import ContractorCoverageModel from '@/models/contractor/ContractorCoverageModel'
import ContractorTradeAvailabilityModel from '@/models/contractor/ContractorTradeAvailabilityModel'
import TradeModel from '@/models/policyHolder/TradeModel'
import PolicyHolderController from '@/api/policyHolderController'
import StarRating from 'vue-star-rating'
import axios from 'axios'
import ContractorModel from '@/models/contractor/ContractorModel'
import ContractorAppointedModel from '@/models/claim/ContractorAppointedModel'
import { constants } from 'fs'
import EmergencyModel from '@/models/policyHolder/EmergencyModel'
import storeGetters from '@/storeGetters'
import storeMutations from '@/storeMutations'
import Shared from '../common/shared'
declare let google: any

export interface LatLng {
  Lat: number
  Lng: number
}

@Component({
  components: { StarRating },
})
export default class GoogleMap extends Vue {
  public resultMap: any = null
  public tooltipObject: any = null
  public coordPropName: any = null
  public offset: any = { x: 20, y: 20 }
  public center: LatLng = { Lat: 51.509865, Lng: -0.118092 }
  public outcodesByArea: any[] = []
  public internalAreas: any[] = []
  public currentFeature!: any
  public currentOutCodes!: any

  public initGoogleMap(mapId: string, zoomLevel: number, disableDefaultUIControl: boolean) {
    return new (Shared.getGoogle().maps.Map)(document.getElementById(mapId)!, {
      fullscreenControl: false,
      zoom: zoomLevel,
      maxZoom: 15,
      disableDefaultUI: disableDefaultUIControl,
      center: new (Shared.getGoogle().maps.LatLng)(this.center.Lat, this.center.Lng),
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.LEFT_BOTTOM,
      },
      mapTypeId: Shared.getGoogle().maps.MapTypeId.ROADMAP,
      mapTypeControl: false,
      streetViewControl: false,
      clickableIcons: false,
    })
  }

  public toggleFullScreenIcon(displayStyle: string) {
    const fullScreenIcons: any = document.getElementsByClassName('fullScreenIcon')
    if (fullScreenIcons && fullScreenIcons.length > 0) {
      for (const item of fullScreenIcons) {
        if (displayStyle === 'show') {
          item.classList.remove('hide')
        } else {
          item.classList.add('hide')
        }
      }
    }
  }

  public moveTooltip(event) {
    if (this.tooltipObject && event && this.coordPropName) {
      // position it
      if (event[this.coordPropName] !== undefined) {
        this.tooltipObject.style.top = event[this.coordPropName].clientY + window.scrollY + this.offset.y + 'px'
        this.tooltipObject.style.left = event[this.coordPropName].clientX + window.scrollX + this.offset.x + 'px'
      }
    }
  }

  public deleteTooltip(event) {
    if (this.tooltipObject) {
      // delete the tooltip if it exists in the DOM
      document.body.removeChild(this.tooltipObject)
      this.tooltipObject = null
    }
  }

  public toggleFullScreenView(showFullScreenMap: boolean, disableDefaultUIControl: boolean) {
    if (disableDefaultUIControl) {
      this.resultMap.disableDefaultUI = !this.resultMap.disableDefaultUI
    }
    if (this.resultMap && this.resultMap.streetView.getVisible() === true) {
      this.resultMap.streetView.setVisible(false)
    }
    const mapView: any = this.$refs.mapView
    if (mapView) {
      if (showFullScreenMap) {
        mapView.classList.add('fullScreen')
        this.toggleFullScreenIcon('hide')
        setTimeout(() => {
          ;(this.$refs.fullScreenIcon as any).$el.classList.remove('hide')
        }, 800)
      } else {
        mapView.classList.remove('fullScreen')
        setTimeout(() => {
          this.toggleFullScreenIcon('show')
        }, 800)
      }

      this.$nextTick()
    }
  }

  public async fillOutcodesByArea() {
    const response = await fetch('/mapdata/outcodes-by-area.json')
    this.outcodesByArea = await response.json()
  }

  public featureClickedHandler(event: any) {
    if (this.currentFeature && !event.feature.getProperty('o') && this.currentFeature !== event.feature) {
      // if we have a current feature, the event feature isn't an outcode
      // and the current feature isn't the event feature
      // clear outcodes
      this.currentOutCodes.forEach((f) => this.resultMap.data.remove(f))
      // add region back
      this.resultMap.data.add(this.currentFeature)
      // update the area incase it was selected and now isn't or vice versa
      this.updateRenderedAreas([this.currentFeature])
    }
    if (!event.feature.getProperty('o')) {
      // handle area selection
      this.currentFeature = event.feature
      // clear data and load next geo data
      this.resultMap.data.remove(event.feature)
      this.resultMap.data.loadGeoJson(
        `/mapdata/${this.currentFeature.getProperty('a')}-geo.json`,
        undefined,
        (features) => {
          this.currentOutCodes = features
          this.updateRenderedAreas(features)
        }
      )
    }
  }

  public runFunctionOnMatchingFeatures(predicate: (feature: any) => true | false, fun: (feature: any) => void) {
    this.resultMap.data.forEach((f) => {
      if (predicate(f)) {
        fun(f)
      }
    })
  }

  public updateRenderedAreas(featureSet?: any) {
    let itterateOn: any
    if (featureSet) {
      itterateOn = featureSet
    } else {
      itterateOn = this.resultMap.data
    }

    if (itterateOn) {
      itterateOn.forEach((feature) => {
        const outCode = feature.getProperty('o')
        if (outCode) {
          if (this.outCodeIsSelected(outCode)) {
            this.resultMap.data.overrideStyle(feature, {
              fillColor: '#1B5E20',
            })
          } else {
            this.resultMap.data.revertStyle(feature)
          }
        } else {
          const areaCode = feature.getProperty('a')
          if (areaCode) {
            if (this.areaIsSelected(areaCode)) {
              if (this.areaHasFullCoverage(areaCode)) {
                this.resultMap.data.overrideStyle(feature, {
                  fillColor: '#4b0082',
                })
              } else {
                this.resultMap.data.overrideStyle(feature, {
                  fillColor: 'yellow',
                })
              }
            } else {
              this.resultMap.data.revertStyle(feature)
            }
          }
        }
      })
    }
  }

  public get internalAreaStrings(): string[] {
    return this.internalAreas.map((ia) => ia.AreaCode)
  }

  public areaIsSelected(areaCode: string): boolean {
    return this.internalAreaStrings.indexOf(areaCode) !== -1
  }

  public get internalOutCodeStrings(): string[] {
    return this.internalAreas.reduce((p, c) => p.concat(...c.OutCodes), [] as string[])
  }

  public outCodeIsSelected(outCode: string): boolean {
    return this.internalOutCodeStrings.indexOf(outCode) !== -1
  }

  public areaHasFullCoverage(areaCode: string): boolean {
    const expectedOutcodes = this.outcodesByArea.find((a) => a.areaCode === areaCode).outcodes as string[]
    return expectedOutcodes.reduce((p, c) => p && this.outCodeIsSelected(c), true as boolean)
  }
}
</script>

<style scoped>
.google-map,
.google-map-street-view {
  height: 100%;
  width: 100%;
  max-width: 100%;
  position: relative;
}
.map-view {
  height: 100%;
}
.fullScreenIcon {
  position: absolute;
  right: 14px;
  top: 11px;
  z-index: 4;
  cursor: pointer;
  background-color: #ffffff;
}

/* Place Card */

.map-container {
  position: relative;
}

.placeDiv {
  z-index: 2;
  position: absolute;
}

.left {
  float: left;
}

.container__map-card {
  box-shadow: rgba(0, 0, 0, 0.298039) 0px 1px 4px -1px;
  width: 100%;
  background: rgb(255, 255, 255) none repeat scroll 0% 0% / auto padding-box border-box;
  border-radius: 2px 2px 2px 2px;
  overflow: hidden;
}

.placecard__container {
  max-width: 330px;
  font: normal normal normal normal 11px / normal Roboto, Arial, sans-serif;
  padding: 9px 9px 9px 11px;
  margin: 10px;
}

.placecard__left {
  float: left;
  width: 82%;
}

.placecard__right {
  text-align: center;
  float: left;
  width: 18%;
}

.placecard__business-name {
  cursor: default;
  height: 19px;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 250px;
  perspective-origin: 100px 9.5px;
  transform-origin: 100px 9.5px;
  font: normal normal 500 normal 14px / normal Roboto, Arial;
  overflow: hidden;
  margin: 0;
  display: block;
  text-align: left;
}

.placecard__info {
  color: rgb(91, 91, 91);
  cursor: default;
  height: auto;
  width: 250px;
  column-rule-color: rgb(91, 91, 91);
  perspective-origin: 100px 16px;
  transform-origin: 100px 16px;
  border: 0px none rgb(91, 91, 91);
  font: normal normal normal normal 12px / normal Roboto, Arial;
  margin: 6px 0px 0px;
  outline: rgb(91, 91, 91) none 0px;
  text-align: left;
}

.placecard__direction-link .v-icon {
  height: 30px;
  width: 30px;
  margin: 0px 5px;
  text-align: center;
  color: rgb(58, 132, 223);
  cursor: pointer;
}

.placecard__direction-link {
  color: rgb(58, 132, 223);
  display: block;
  height: 43px;
  text-decoration: none;
  width: 54.7344px;
}

.placecard__view-large {
  display: inline-block;
  margin-top: 10px;
  color: rgb(58, 132, 223);
  text-decoration: none;
  font-size: 12px;
}

.placecard__review {
  font-size: 12px;
  padding-left: 10px;
}

@media only screen and (max-width: 1650px) {
  .placecard__info,
  .placecard__direction-link {
    display: none;
  }
  .placecard__info + div {
    padding-top: 0px !important;
  }
}
/* END Place Card */

.container__map-card-direction {
  max-width: 270px;
  font: normal normal normal 14px / normal Roboto, Arial;
  margin: 10px 15px;
}

.map-card-direction {
  z-index: 4;
  position: absolute;
  right: 10px;
}

.map-card-direction-value {
  font-weight: 500;
  float: left;
}

.map-icons .v-icon {
  height: 40px;
  width: 40px;
  margin: 0px 5px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}
.map-icons .v-icon.active {
  background-color: #3367d6;
}
.map-icons {
  text-align: center;
  background: #4285f4;
}
/* END Travel Card */

/* star-rating CSS */
.placecard__left >>> .vue-star-rating-rating-text {
  font-size: 14px;
  color: #e7711b;
  font-weight: 500;
  margin-top: 0px !important;
  margin-left: 0px !important;
  position: absolute;
}

.placecard__left >>> .vue-star-rating {
  padding-top: 5px;
}

.placecard__left >>> .vue-star-rating span:first-of-type {
  margin-left: 28px;
}
/* END star-rating CSS */
/*Responsive*/
@media (max-width: 1280px) {
  .map-view {
    overflow: hidden;
  }
}

@media (max-width: 1500px) {
  .placeDiv,
  .placeDiv >>> .container__map-card {
    width: calc(100% - 30px);
  }
  .placecard__business-name {
    width: 100%;
  }
}
</style>
