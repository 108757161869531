<template>
  <div v-if="jobOffer">
    <v-layout v-if="jobOfferExpired">
      <h3>Job Offer is no longer available</h3>
    </v-layout>
    <v-layout v-else>
      <v-flex xs12 px-2 py-1>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Job:</label>
          <span>{{ jobOffer.contractorAppointedModel.jobId }}</span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Post Code:</label>
          <span>{{ jobOffer.contractorAppointedModel.postcode }}</span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Client Name:</label>
          <span>{{ jobOffer.contractorAppointedModel.customerName }}</span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Emergency</label>
          <span>{{ jobOffer.emergencyTypeDescription }}</span>
          <span v-if="jobOffer.emergencyDetailDescription">
            {{ jobOffer.emergencyDetailDescription }}
          </span>
        </div>
        <div class="mb-2">
          <label class="mr-1 font-weight-bold">Address:</label>
          <span>{{ jobOffer.contractorAppointedModel.address }}</span>
        </div>
        <div v-if="jobOffer.engineerSiteSla">
          <label class="mr-1 font-weight-bold">SLA cut off time :</label>
          <span>{{ formatDate(jobOffer.engineerSiteSla) }}</span>
        </div>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex class="mr-3">
        <DateTimePicker
          ref="etaFrom"
          :date-time.sync="etaFrom"
          :is-static-location="false"
          place-holder-text="ETA From"
          :is-validation-required="true"
          :allowed-minutes-step="true"
        />
      </v-flex>
      <v-flex>
        <DateTimePicker
          ref="etaTo"
          :date-time.sync="etaTo"
          :is-static-location="false"
          place-holder-text="ETA From"
          :is-validation-required="true"
          :allowed-minutes-step="true"
        />
      </v-flex>
    </v-layout>
    <v-layout>
      <h4 v-if="etaErrorMessage" class="warning--text">{{ etaErrorMessage }}</h4>
    </v-layout>
    <v-flex>
      <CustomerAvailabilityDisplay :availability="jobOffer.customerAvailability" />
    </v-flex>
    <v-divider class="mt-2"></v-divider>

    <v-layout class="justify-end">
      <v-flex xs4 class="d-flex justify-end">
        <v-btn class="red white--text" @click="showRejectJobConfirmationDialog = true"> Reject Job </v-btn>
        <v-btn class="green white--text" @click="acceptJob"> Accept Job </v-btn>
      </v-flex>
    </v-layout>

    <v-dialog v-model="showRejectJobConfirmationDialog" width="400" persistent>
      <v-card>
        <v-toolbar card dark color="primary">
          <v-toolbar-title>Reject Job</v-toolbar-title>
        </v-toolbar>
        <v-card-text>Are you sure you want to reject this job?</v-card-text>
        <v-card-actions>
          <v-btn class="white--text" color="blue" @click="showRejectJobConfirmationDialog = false">Cancel</v-btn>
          <v-btn class="white--text" color="red" @click="setContractorDecisionToRejectJob">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <v-progress-circular
    v-else-if="isJobOfferDetailsLoading && !jobOffer"
    class="loading-spinner"
    :width="2"
    :size="50"
    indeterminate
    color="primary"
  ></v-progress-circular>
  <div v-else><h1>Failed to load job details</h1></div>
</template>

<script lang="ts">
import Vue from 'vue'
import Component from 'vue-class-component'
import DeploymentController from '@/api/deployment-controller'
import { AppointmentDetail } from '@/api/models/deployment/contractor/appointment-detail'
import CustomerAvailabilityDisplay from '../CustomerAvailabilityDisplay.vue'
import DateTimePicker from '../DateTimePicker.vue'
import moment, { Moment } from 'moment'
import { TimeSlot } from '@/api/models/deployment/contractor/time-slot-response'
import Shared from '@/common/shared'
import { useDeploymentStore } from '@/pinia/deployment'
import { storeToRefs } from 'pinia'
import { AcceptJobOfferRequest } from '@/api/models/deployment/accept-job-offer-request'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'

@Component({
  components: {
    CustomerAvailabilityDisplay,
    DateTimePicker,
  },
})
export default class JobOffer extends Vue {
  private jobOfferExpired = false

  public etaFrom: Moment | null = null
  public etaTo: Moment | null = null
  public etaErrorMessage: string | null = null

  public isJobOfferDetailsLoading = true

  public showRejectJobConfirmationDialog = false

  public async created() {
    const deploymentStore = useDeploymentStore()
    const { jobId, appointmentId } = this.deploymentDetail
    if (!jobId.value || !appointmentId.value) {
      this.isJobOfferDetailsLoading = false
      return
    }
    try {
      await deploymentStore.retrieveJobOffer(jobId.value, appointmentId.value)
    } catch (err) {
      this.$store.dispatch(
        'snackbarModule/showSnackbar',
        new ShowErrorSnackbar('Failed to load job offer details, please contact support')
      )
    }
    this.isJobOfferDetailsLoading = false
  }

  public get deploymentDetail() {
    const { jobId, appointmentId } = storeToRefs(useDeploymentStore())
    return {
      jobId,
      appointmentId,
    }
  }

  public setContractorDecisionToRejectJob() {
    const deploymentStore = useDeploymentStore()
    deploymentStore.isJobOfferRejected = true
    this.showRejectJobConfirmationDialog = false
  }

  public get jobOffer(): AppointmentDetail | null {
    const { jobOffer } = storeToRefs(useDeploymentStore())
    return jobOffer.value
  }

  private isTimeWithinCustomerSchedule(timeSlots: TimeSlot[], startTime: Moment, endTime: Moment) {
    if (!timeSlots.length) {
      return false
    }

    const sortedTimeSlots = timeSlots.sort((a, b) => moment(a.startTime).diff(moment(b.startTime)))

    let currentRange = { start: moment(sortedTimeSlots[0].startTime), end: moment(sortedTimeSlots[0].endTime) }

    for (const slot of sortedTimeSlots) {
      const slotStart = moment(slot.startTime)
      const slotEnd = moment(slot.endTime)

      if (!slotStart.isSame(currentRange.end)) {
        if (currentRange && startTime.isSameOrAfter(currentRange.start) && endTime.isSameOrBefore(currentRange.end)) {
          return true
        }
        currentRange = { start: slotStart, end: slotEnd }
      } else {
        currentRange.end = slotEnd
      }

      if (startTime.isSameOrAfter(currentRange.start) && endTime.isSameOrBefore(currentRange.end)) {
        return true
      }
    }
    return false
  }

  // This should be done using vee-validate, but the current DateTimePicker component is a mess and needs re-written
  private async validateDates(): Promise<boolean> {
    if (!this.jobOffer) {
      this.etaErrorMessage = 'No job appointment data available. Please contact support.'
      return false
    }

    const isEtaFromValid: boolean = await (this.$refs.etaFrom as DateTimePicker).$validator.validateAll()
    const isEtaToValid: boolean = await (this.$refs.etaFrom as DateTimePicker).$validator.validateAll()

    if (!isEtaToValid || !isEtaFromValid || !this.etaFrom || !this.etaTo) {
      this.etaErrorMessage = 'Please select an estimated timescale for arrival'
      return false
    }

    if (this.etaFrom > this.etaTo) {
      this.etaErrorMessage = 'Estimated arrival start time must be less than estimated arrival end time'
      return false
    }

    // Is the contractors selected time range is outside of the SLA.
    if (
      !this.jobOffer.firstUpdatedAt ||
      (this.etaFrom < moment(this.jobOffer.firstUpdatedAt) && this.etaTo > moment(this.jobOffer.engineerSiteSla))
    ) {
      this.etaErrorMessage = 'Cannot deploy a job outside of the SLA timeframe'
      return false
    }

    if (!this.isTimeWithinCustomerSchedule(this.jobOffer.customerAvailability.timeSlots, this.etaFrom, this.etaTo)) {
      this.etaErrorMessage = 'The times you have selected fall outside the customers availability'
      return false
    }

    this.etaErrorMessage = null
    return true
  }

  public async acceptJob() {
    if (!(await this.validateDates())) return
    if (!this.jobOffer) {
      //do something
      return
    }

    const request: AcceptJobOfferRequest = {
      jobId: this.deploymentDetail.jobId.value!,
      emergencyId: this.jobOffer.contractorAppointedModel.emergencyId,
      legacyAppointmentId: this.jobOffer.contractorAppointedModel.id,
      deploymentId: this.jobOffer.deploymentId,
      appointmentRequestId: this.jobOffer.appointmentRequestId,
      contractorId: this.jobOffer.contractorAppointedModel.contractorId,
      etaTo: this.etaTo!.toDate(),
      etaFrom: this.etaTo!.toDate(),
    }
    const result = await DeploymentController.AcceptJobOffer(request)
    if (result) {
      useDeploymentStore().isJobOfferAccepted = true
    }
  }

  public formatDate(date: Date) {
    return Shared.getFormatedDate(moment(date), this.$store.state.Environment.DateTimeFormat)
  }
}
</script>
