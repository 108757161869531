<template>
  <div>
    <div :id="mapOptions.mapId" class="wrapper"></div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'
import Shared from '@/common/shared'
import Map from './Map.vue'
import eventBus from '@/common/bus'
import ShowErrorSnackbar from '@/models/snackbar/show-error-snackbar'

export class GoogleMapOptions {
  public mapId: string
  public postcode: string
}

@Component({
  components: {
    Map,
  },
})
export default class InteractiveMap extends Vue {
  @Prop()
  public mapOptions: GoogleMapOptions

  public get google() {
    return Shared.getGoogle()
  }

  public mounted() {
    this.generateMap()
  }

  public async generateMap() {
    try {
      const map = new this.google.maps.Map(document.getElementById(this.mapOptions.mapId)!, {
        zoom: 7,
        mapTypeControl: false,
        streetViewControl: false,
        clickableIcons: false,
        mapId: this.mapOptions.mapId,
      })

      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ address: this.mapOptions.postcode }, (results, status) => {
        if (status === this.google.maps.GeocoderStatus.OK && results) {
          map.setCenter(results[0].geometry.location)
          results.forEach((x) => this.addMarker(x.geometry.location, map))
        }
      })

      map.setZoom(14)
    } catch (err: any) {
      eventBus.$emit('errorHandler', err)
      this.$store.dispatch('snackbarModule/showSnackbar', new ShowErrorSnackbar(`Unable to load map for deployment`))
    }
  }

  private addMarker(position, map) {
    new this.google.maps.marker.AdvancedMarkerElement({
      position: new this.google.maps.LatLng(position),
      map: map,
    })
  }
}
</script>

<style scoped lang="css">
.wrapper {
  height: 220px;
  width: 100%;
}
</style>
