<template>
  <v-layout row fill-height class="wrapper" pa-2>
    <v-flex xs6>
      <v-layout row wrap>
        <v-flex xs3 class="font-weight-bold"> Postcode:</v-flex>
        <v-flex xs6>
          {{ deployment.postcode }}
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex xs3 class="font-weight-bold">Trade:</v-flex>
        <v-flex xs6> {{ deployment.trade || 'Not Set' }} </v-flex>
      </v-layout>

      <!-- Delays will be added in later -->
      <!-- <v-layout v-if="deployment.delays.length" row>
        <v-flex xs6 class="font-weight-bold">Delays: </v-flex>
        <v-flex xs6>
          <v-chip
            v-for="(delay, index) in deployment.delays"
            :key="`${delay.delayReason}-${index}`"
            small
            color="blue"
            text-color="white"
            >{{ delay.delayReason }}</v-chip
          >
        </v-flex>
      </v-layout> -->
    </v-flex>

    <v-flex xs6>
      <div class="pa-2">
        <InteractiveMap v-if="googleMapOptions" :map-options="googleMapOptions" />
        <h2 v-else>Unable to load map</h2>
      </div>
    </v-flex>
  </v-layout>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator'
import { AutoDeployment, DeploymentStatusMap } from '@/models/autoDeployment/auto-deployment'
import { formatDateTime } from '@/common/datetime'
import InteractiveMap from '@/components/InteractiveMap.vue'
import { GoogleMapOptions } from '@/components/InteractiveMap.vue'

@Component({
  components: {
    InteractiveMap,
  },
})
export default class AutoDeploymentDetail extends Vue {
  @Prop({ default: null })
  public deployment: AutoDeployment

  public formatDateTime = formatDateTime
  public deploymentStatusMap = DeploymentStatusMap

  public get googleMapOptions(): GoogleMapOptions | null {
    const { postcode } = this.deployment
    if (!postcode) {
      return null
    }

    const options: GoogleMapOptions = {
      mapId: 'auto-deployment-map-id',
      postcode,
    }
    return options
  }
}
</script>

<style scoped>
.v-chip--small {
  height: 12px !important;
  font-size: 12px;
  padding: 10px 0px;
  margin: 0;
}

.wrapper {
  background: white;
}
</style>
