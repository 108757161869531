<template>
  <div class="login-callback">
    <div class="login-callback__status">
      <span class="status__message">{{ statusMessage }}</span>
    </div>
    <div v-if="errorMessage" class="login-callback__error">
      <div class="error__message">
        <label class="message__label">Error:</label>
        <span class="message__text">{{ errorMessage }}</span>
      </div>
      <div class="error__session">
        <label class="session__label">Session ID:</label>
        <span class="session__text">{{ sessionId }}</span>
      </div>
      <div class="error__retry">
        <div>Failed to log you in, please retry and if this issue happens again contact support for assistance.</div>
        <a href="#" @click.prevent="redirectHome">Retry</a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator'
import { SetThemeForJobType } from '@/common/themes'
import { Action, Getter } from 'vuex-class'
import SessionController from '@/api/sessionController'
import eventBus from '@/common/bus'
import { getSessionId } from '@/plugins/datadog'

@Component({})
export default class Callback extends Vue {
  public statusMessage = ''
  public errorMessage = ''
  @Action('oidcSignInCallback')
  private oidcSignInCallback: () => Promise<string>
  @Getter('oidcAccessToken') private oidcAccessToken: string | null

  public get sessionId(): string | null {
    return getSessionId() ?? null
  }

  private redirectHome() {
    this.$router.replace('/')
  }

  private async mounted() {
    SetThemeForJobType(this, undefined)
    try {
      this.errorMessage = ''
      this.statusMessage = 'Logging you in, please wait ...'

      const redirectPath = await this.oidcSignInCallback()
      this.statusMessage = 'Login successful, checking authorisation ...'
      if (this.oidcAccessToken) {
        await SessionController.LoadSession(this.oidcAccessToken)
        await this.$store.dispatch('authModule/submitGetAuthPolicies')
        this.statusMessage = 'Session loaded, taking you home...'

        // We need to catch the error here because the navigation guard will intercept and redirect towards the user's homepage.
        // The vue router considers this redirection as a navigation failure and will throw an error.
        // Source: https://stackoverflow.com/questions/62223195/vue-router-uncaught-in-promise-error-redirected-from-login-to-via-a
        await this.$router.replace(redirectPath || '/').catch(() => {})
      } else {
        this.statusMessage = 'Unable to log you in.'
        this.errorMessage = 'Access token missing.'
      }
    } catch (err) {
      if (err instanceof Error && err.cause === 401) {
        this.$router.push('/unauthorized')
        return
      }

      const props = {
        component: 'Callback',
        method: 'mounted',
        oidcUser: this.$store.getters['oidcUser'],
        oidcError: this.$store.getters['oidcError'],
      }
      eventBus.$emit('errorHandler', err, false, null, props)

      this.statusMessage = 'Unable to log you in.'
      this.errorMessage = err instanceof Error ? err.message : String(err)
    }
  }
}
</script>

<style scoped>
.error__retry {
  margin-top: 1rem;
}

.login-callback__error {
  margin-top: 1rem;
}

.session__label,
.message__label {
  font-weight: bold;
  display: inline-block;
  margin-right: 1rem;
}

.session__text {
  font-family: ui-monospace, monospace, Consolas, 'Courier New';
}
</style>
